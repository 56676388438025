/* eslint-disable indent */
import { Box, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import Infos from '../../Templates/Infos/Infos';
import { FamilyType, Member, InvitationType } from '../../../types/Family.type';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Fire from '../../../services/fire.service';
import { useAlert } from '../../../hooks/useAlert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { useNavigate } from 'react-router';
import { updateUser } from '../../../redux/slices/user/userSlice';
import PendingMembers from './PendingMembers/PendingMembers';
import Members from './Members/Members';

interface MembersModalProps {
  open: boolean;
  onClose: () => void;
  family: FamilyType;
}

const MembersModal: React.FC<MembersModalProps> = ({
  open,
  onClose,
  family,
}) => {
  const [value, setValue] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [members, setMembers] = React.useState<Member[]>(family?.members || []);
  const [pendingMembers, setPendingMembers] = React.useState<string[]>(
    family?.pendingMembers || []
  );
  const { showAlert } = useAlert();
  const user = useSelector((state: RootState) => state.userReducer.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const membersRef = React.useRef<HTMLDivElement>(null);

  const initials =
    family?.title.split(' ').length === 1
      ? Array.from(family?.title || '')[0] || ''
      : `${Array.from(family?.title.split(' ')[0] || '')[0] || ''}${
          Array.from(family?.title.split(' ')[1] || '')[0] || ''
        }`;
  const name = `Famille ${family?.title || ''}`;

  const sendInvitEmail = async (to) => {
    await Fire.cloud('sendMail', {
      user: {
        email: to,
      },
      templateId: 2,
      params: {
        familyName: family.title,
        expeditor: {
          firstName: user.firstName,
        },
      },
    })
      .then(() => {
        showAlert(
          'Invitation envoyée ! La personne en question recevra un email pour rejoindre la famille',
          'success'
        );
      })
      .catch(() => {
        showAlert(
          "Une erreur est survenue lors de l'envoi de l'invitation",
          'error'
        );
      });
  };

  React.useEffect(() => {
    if (!family) return;
    setMembers(family.members);
    setPendingMembers(family.pendingMembers);
  }, [family]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
    setError('');
  };

  const onAddMember = async () => {
    if (!value) {
      setError('Veuillez renseigner un email');
      return;
    }
    // check if valid email
    const regex = /\S+@\S+\.\S+/;
    if (!regex.test(value)) {
      setError('Veuillez renseigner un email valide');
      return;
    }
    if (members.find((member) => member.email === value)) {
      setError('Cet email est déjà membre de la famille');
      return;
    }
    if (pendingMembers.find((member) => member === value)) {
      setError('Cet email est déjà en attente de validation');
      return;
    }

    try {
      await Fire.update('families', family.id, {
        pendingMembers: [...pendingMembers, value],
      });

      // send email

      const invitation: InvitationType = {
        family: {
          id: family.id,
          title: family.title,
          membersLength: family.members.length,
          recipesLength: family.recipes.length,
        },
        user: value,
        expirateAt: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
        expeditor: {
          id: user.id,
          email: user.email,
          username: user.username,
        },
      };

      const invitationPath = await Fire.add('invitations', invitation);
      const invitationId = invitationPath.id;

      const userRef = Fire.store()
        .collection('users')
        .where('email', '==', value);
      const userExists = await Fire.list(userRef);

      if (userExists && userExists.length > 0) {
        const newUser = userExists[0];

        Fire.update('users', newUser.id, {
          invitations: [...newUser.invitations, invitationId],
        });
      }
      setValue('');

      setPendingMembers([...pendingMembers, value]);

      await sendInvitEmail(value);
    } catch (err) {
      showAlert(
        "Une erreur est survenue lors de l'envoi de l'invitation",
        'error'
      );
    }
  };

  const onDeleteMember = async (member: Member) => {
    if (
      window.confirm(
        user.id === member.id
          ? `Êtes-vous sûr de vouloir quitter la famille ?`
          : `Êtes-vous sûr de vouloir supprimer ${member.firstName} ${member.lastName} de la famille ?`
      )
    ) {
      const newMembers = members.filter((m) => m.id !== member.id);
      setMembers(newMembers);

      if (newMembers.length === 0) {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer la famille ?')) {
          await Fire.delete('families', family.id);
          // delele all invitations
          const invitationsRef = Fire.store()
            .collection('invitations')
            .where('family.id', '==', family.id);
          const invitations = await Fire.list(invitationsRef);
          invitations.forEach(async (invitation) => {
            await Fire.delete('invitations', invitation.id);
          });

          showAlert('La famille a bien été supprimée', 'success');
        }
      } else {
        await Fire.update('families', family.id, {
          members: newMembers,
        });
        showAlert('Le membre a bien été supprimé de la famille', 'success');
      }

      if (user.id === member.id) {
        const newUserFamiles = user.families.filter(
          (familyId) => familyId !== family.id
        );
        Fire.update('users', user.id, {
          families: newUserFamiles,
        });
        dispatch(
          updateUser({
            families: newUserFamiles,
          })
        );

        onClose();
        navigate('/');
      }
    }
  };

  const onDeletePendingMember = async (member: string) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir annuler l'incitation de ${member} ?`
      )
    ) {
      const newPendingMembers = pendingMembers.filter((m) => m !== member);

      await Fire.update('families', family.id, {
        pendingMembers: newPendingMembers,
      });

      // delete invitiation

      const invitationsRef = Fire.store()
        .collection('invitations')
        .where('family.id', '==', family.id)
        .where('user', '==', member);

      await Fire.list(invitationsRef).then((invitations) => {
        invitations.forEach((invitation) => {
          Fire.delete('invitations', invitation.id);
        });
      });

      // if user exists, delete invitation from user

      const userRef = Fire.store()
        .collection('users')
        .where('email', '==', member);

      await Fire.list(userRef).then((users) => {
        if (users && users.length > 0) {
          const newUser = users[0];
          const newInvitations = newUser.invitations.filter(
            (invitation) => invitation !== invitation.id
          );
          Fire.update('users', newUser.id, {
            invitations: newInvitations,
          });
        }
      });

      setPendingMembers(newPendingMembers);
      showAlert('Le membre a bien été supprimé de la famille', 'success');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Membres de la famille"
      aria-describedby="Ce modal sert à gérer les membres de la famille"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: '2rem',
          background: 'white',
          borderRadius: 3,
          width: '90%',
          maxWidth: 700,
          height: '75vh',
          maxHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '6%',
            right: '2rem',
            cursor: 'pointer',
          }}
        >
          <CloseRoundedIcon onClick={onClose} />
        </Box>
        <Infos
          isFamily
          initials={initials as string}
          name={name}
          isLoading={false}
          nbMembers={family?.members.length || 0}
          small
          sx={{
            paddingBottom: '2rem',
          }}
        />
        <Typography
          variant="h3"
          sx={{
            fontSize: '1.25rem',
            fontWeight: 500,
            marginBottom: '1rem',
          }}
        >
          Invitez un membre dans votre famille :
        </Typography>
        <TextField
          variant="outlined"
          placeholder="john.doe@gmail.com"
          fullWidth
          name="introduction"
          onChange={(e) => onChange(e)}
          value={value || ''}
          helperText={error}
          sx={{
            marginBottom: '1rem',
            input: {
              padding: '.75rem 1rem',
            },
            // helper text
            '& .MuiFormHelperText-root': {
              color: 'error.main',
            },
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onAddMember();
            }
          }}
          InputProps={{
            endAdornment: (
              <SendRoundedIcon
                sx={{
                  cursor: 'pointer',
                  transform: 'rotate(-35deg) translateY(-2px)',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    opacity: 0.7,
                  },
                }}
                onClick={onAddMember}
              />
            ),
          }}
        />

        <Box
          sx={{
            marginTop: '.5rem',
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '100%',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              width: '6px',
              backgroundColor: '#F5F5F5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              backgroundColor: '#aaa',
            },
          }}
          ref={membersRef}
        >
          {!!pendingMembers.length && (
            <PendingMembers
              pendingMembers={pendingMembers}
              onDeletePendingMember={onDeletePendingMember}
              sendInvitEmail={sendInvitEmail}
            />
          )}
          {!!members.length && (
            <Members members={members} onDeleteMember={onDeleteMember} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default MembersModal;

