/* eslint-disable indent */
import React from 'react';
import { Button, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
  path?: string;
  title: string;
  shadow?: boolean;
  onClick?: (e: any) => void;
  margin?: string;
  fullWidth?: boolean;
  onKeyDown?: (e: any) => void;
  disabled?: boolean;
  smallRadius?: boolean;
  smallPadding?: boolean;
  sx?: any;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  grey?: boolean;
  green?: boolean;
  red?: boolean;
  arrowLeft?: boolean;
  component?: React.ElementType;
  variant?: 'text' | 'outlined' | 'contained';
}

export default function ButtonFilled(props: Props): JSX.Element {
  const {
    path,
    title,
    onClick,
    margin,
    fullWidth,
    onKeyDown,
    disabled,
    smallRadius,
    smallPadding,
    startIcon,
    endIcon,
    grey,
    green,
    red,
    arrowLeft,
    component,
    variant,
  } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      disabled={disabled}
      variant={variant || 'contained'}
      color="primary"
      component={component}
      sx={{
        color:
          variant === 'text' ? 'primary.main' : grey ? 'text.primary' : 'white',
        backgroundColor:
          variant === 'text'
            ? 'transparent'
            : grey
            ? '#F4F7F8'
            : green
            ? 'success.main'
            : red
            ? 'error.main'
            : 'primary.main',
        borderRadius: smallRadius ? 1 : 100,
        padding: smallPadding
          ? '.2rem 1rem'
          : { xs: '.4rem 2.5rem', sm: '.3rem 2rem' },
        textTransform: 'none',
        boxShadow: 'none',
        margin: margin || 'inherit auto',
        width: fullWidth ? '100%' : 'fit-content',
        ...props.sx,

        '&:hover': {
          boxShadow: 'none',
          backgroundColor: grey
            ? '#F1F1F1'
            : green
            ? 'success.dark'
            : red
            ? 'error.dark'
            : 'primary.dark',
          color: grey ? 'text.primary' : 'white',
        },
      }}
      startIcon={startIcon ? startIcon : arrowLeft ? <ArrowBackIcon /> : null}
      endIcon={endIcon}
      onClick={(e) => (path ? navigate(path) : onClick ? onClick(e) : '')}
      onKeyDown={(e: any) => {
        onKeyDown ? onKeyDown(e) : '';
      }}
    >
      <Typography
        variant={mobile ? 'h5' : 'h6'}
        sx={{
          fontSize: {
            xs: '1.2rem',
            sm: '1.1rem',
          },
        }}
      >
        {title}
      </Typography>
    </Button>
  );
}

