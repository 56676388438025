import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './routes';
import { theme } from './theme/_theKitchenBookTheme';
import { ThemeProvider } from '@mui/material/styles';
import { Fire } from './services';
import AlertContextProvider from './contexts/AlertContext';
import LoginContextProvider from './contexts/LoginContext';
import { useDispatch, useSelector } from 'react-redux';
import { logout, login, setLoading } from './redux/slices/user/userSlice';
import CreateRecipeModal from './components/CreateRecipeModal/CreateRecipeModal';

declare module '*.png';
declare module '*.jpeg';
declare module '*.jpg';
declare module '*.svg';

Fire.init();

const App: React.FC = () => {
  const [isUserChecked, setIsUserChecked] = React.useState<boolean>(false);
  const [userRoutes, setUserRoutes] = React.useState<any>([]);
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.userReducer.user);

  React.useEffect(() => {
    Fire.auth().onAuthStateChanged(async (user) => {
      dispatch(setLoading(true));
      if (user) {
        const fireUser = await Fire.get('users', user.uid);
        await dispatch(login(fireUser));
      } else {
        await dispatch(logout());
      }
      setIsUserChecked(true);
      await dispatch(setLoading(false));
    });
  }, []);

  React.useEffect(() => {
    if (!isUserChecked) return;
    const payload = routes.filter((item) => {
      if (item.both === true) return true;
      if (item.connected === undefined) return true;
      if (item.connected === false && !user) return true;
      if (item.connected === true && user) return true;
      return false;
    });

    setUserRoutes(payload);
  }, [routes, user, isUserChecked]);

  return (
    <LoginContextProvider>
      <AlertContextProvider>
        <ThemeProvider theme={theme}>
          {!!userRoutes.length && (
            <Router>
              <Routes>
                {userRoutes.map((item, index) => (
                  <Route
                    key={index}
                    path={item.path}
                    Component={item.component}
                  />
                ))}
              </Routes>

              <CreateRecipeModal />
            </Router>
          )}
        </ThemeProvider>
      </AlertContextProvider>
    </LoginContextProvider>
  );
};

export default App;

