import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';
import Logo from '../../../images/Misc/Logo.svg';

const Footer = () => {
  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        backgroundColor: 'background.default',
        padding: { xs: '3rem 2rem', md: '4.75rem 2rem' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          '& img': {
            width: { xs: '3rem', sm: '4rem', md: '5rem' },
          },
        }}
      >
        <img src={Logo} alt="Logo The Kitchen Book" />
        <Typography
          variant="h6"
          sx={{
            color: 'primary.main',
            fontWeight: 600,
            fontSize: {
              xs: '1.75rem',
              sm: '2.25rem',
            },
            cursor: 'pointer',
            fontFamily: 'Poetsen One',
            marginLeft: { xs: '1rem', sm: '1.5rem', md: '2rem' },
          }}
        >
          The Kitchen Book
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={{
          marginTop: '2rem',
          fontSize: '1rem',
          color: '#8B8B8B',
          textAlign: 'center',
        }}
      >
        copyright @2023 The Kitchen Book - Tous droits réservés <br /> Fièrement
        créé par{' '}
        <Link
          href="https://aveko.fr"
          target="_blank"
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
            transition: 'all .2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          Aveko
        </Link>
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginTop: '1rem', fontSize: '1rem', color: '#8B8B8B' }}
      >
        <Link
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
            transition: 'all .2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
              color: 'primary.main',
            },
          }}
          href="/terms"
          target="_blank"
        >
          Mentions légales
        </Link>{' '}
        -{' '}
        <Link
          sx={{
            color: 'text.primary',
            textDecoration: 'none',
            transition: 'all .2s ease-in-out',
            cursor: 'pointer',
            '&:hover': {
              color: 'primary.main',
            },
          }}
          href="/privacy"
          target="_blank"
        >
          Politique de confidentialité
        </Link>
      </Typography>
    </Grid>
  );
};

export default Footer;

