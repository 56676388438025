/* eslint-disable indent */
import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonFilled from '../../Buttons/ButtonFilled';
import AddIcon from '@mui/icons-material/Add';
interface SectionTitlePros {
  title: string;
  button: {
    title: string;
    onClick: () => void;
  };
  justify?:
    | 'left'
    | 'center'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

const SectionTitle: React.FC<SectionTitlePros> = ({
  title,
  button,
  justify,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: justify
          ? justify
          : button
          ? 'space-between'
          : 'flex-start',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: '1.5rem',
          fontWeight: 600,
          marginRight: {
            xs: '1rem',
            md: '2rem',
          },
        }}
      >
        {title}
      </Typography>
      {button && (
        <ButtonFilled
          smallRadius
          smallPadding
          startIcon={
            <AddIcon
              sx={{
                color: 'white',
              }}
              fontSize="large"
            />
          }
          {...button}
        />
      )}
    </Box>
  );
};

export default SectionTitle;

