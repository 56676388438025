import React from 'react';
import { Fade, Grid, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { gradesColor, grades } from '../../../utils/utils';
import { Grade } from '../../../types/User.type';

interface InfosProps {
  initials: string;
  name: string;
  isUser?: boolean;
  isFamily?: boolean;
  isLoading: boolean;
  nbRecipes?: number;
  userGrade?: Grade;
  nbMembers?: number;
  small?: boolean;
  sx?: any;
  white?: boolean;
  onOpenMembers?: () => void;
  handleOpenGradeModal?: () => void;
}

const Infos: React.FC<InfosProps> = ({
  initials,
  name,
  isUser,
  isFamily,
  nbRecipes,
  isLoading,
  userGrade,
  nbMembers,
  onOpenMembers,
  handleOpenGradeModal,
  small,
  sx,
  white,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={isUser ? 4 : 12}
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Box
        sx={{
          width: { xs: small ? 80 : 100, sm: small ? 115 : 150 },
          height: { xs: small ? 80 : 100, sm: small ? 115 : 150 },
          background: white ? 'white' : '#F4F7F8',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Fade in={!isLoading} timeout={500}>
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: small ? 40 : 45, sm: small ? 45 : 55 },
              fontFamily: 'Poetsen One',
              opacity: isLoading ? 0 : 1,
              color: isUser ? 'text.primary' : 'primary.main',
              textTransform: 'uppercase',
            }}
          >
            {initials || ''}
          </Typography>
        </Fade>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
          maxWidth: { xs: 'calc(100% - 100px)', sm: 'calc(100% - 150px)' },
          paddingLeft: '2rem',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: {
              xs: small ? '1.8rem' : '2rem',
              sm: small ? '2.1rem' : '2.5rem',
            },
            fontWeight: 500,
            lineHeight: '135%',
            marginBottom: small ? 0 : '.5rem',
          }}
        >
          {isLoading ? <Skeleton variant="text" width={200} /> : name}
        </Typography>
        {nbRecipes != undefined && (
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.15rem',
              fontWeight: 500,
              marginTop: '.5rem',
            }}
          >
            {isLoading ? (
              <Skeleton variant="text" width={150} />
            ) : (
              `${isUser ? 'Auteur de ' : ''}
              ${isLoading ? '0' : nbRecipes} recette${nbRecipes > 1 ? 's' : ''}`
            )}
          </Typography>
        )}
        {isUser && (
          <Box
            sx={{
              padding: '.4rem 1.5rem',
              borderRadius: 20,
              background: '#F4F7F8',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '.75rem',
              cursor: 'pointer',
              transition: 'all .2s ease-in-out',

              '&:hover': {
                backgroundColor: 'primary.main',
                color: 'white',

                '& svg': {
                  color: 'white',
                },
              },
            }}
            onClick={handleOpenGradeModal}
          >
            <WorkspacePremiumIcon
              sx={{
                transition: 'all .2s ease-in-out',
                marginRight: '.5rem',
                opacity: isLoading ? 0 : 1,
                color: gradesColor[userGrade],
              }}
            />
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '1.1rem', sm: '1rem' },
                fontWeight: 500,
                minWidth: 'max-content',
              }}
            >
              {!isLoading && grades[userGrade]}
            </Typography>
          </Box>
        )}
        {isFamily && (
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.15rem',
              fontWeight: 500,
              marginBottom: '.5rem',
              marginTop: '.5rem',
              textDecoration: onOpenMembers ? 'underline' : 'normal',
              cursor: onOpenMembers ? 'pointer' : 'default',
            }}
            onClick={onOpenMembers}
          >
            {nbMembers || '0'} membre
            {nbMembers > 1 && 's'}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

export default Infos;

