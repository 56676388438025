import { Typography } from '@mui/material';
import React from 'react';

interface TitleProps {
  title: string;
  small?: boolean;
  black?: boolean;
  sx?: any;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Title: React.FC<TitleProps> = ({
  title,
  small,
  black,
  sx,
  variant = 'h2',
}) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontSize: {
          md: small ? '2rem' : '2.5rem',
          sm: small ? '1.75rem' : '2.25rem',
          xs: small ? '1.75rem' : '2.25rem',
        },
        fontWeight: 500,
        textAlign: 'center',
        color: black ? 'text.primary' : 'primary.main',
        fontFamily: 'Poetsen One',
        ...sx,
      }}
    >
      {title}
    </Typography>
  );
};

export default Title;

