import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Fire } from '../../../services';
import { FamilyType } from '../../../types/Family.type';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import CustomLabel from '../../Templates/Inputs/CustomLabel';
import InfoIcon from '@mui/icons-material/Info';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Step1Props {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string[]>
  ) => void;
  families: string[];
  isPublic: boolean;
  onChangeIsPublic: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Step1: React.FC<Step1Props> = ({
  onChangeIsPublic,
  onChange,
  families,
  isPublic,
}) => {
  const [familyOptions, setFamilyOptions] = React.useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const user = useSelector((state: any) => state.userReducer.user);

  const getFamilyOptions = async (families: FamilyType[]) => {
    const payload = await Promise.all(
      families.map(async (family) => {
        return {
          label: `Famille ${family.title}`,
          value: family.id,
        };
      })
    );
    setFamilyOptions(payload);
  };

  const getUserFamilies = async (ids: string[]) => {
    const payloadFamilies = await Promise.all(
      ids.map(async (id) => {
        const ref = Fire.store().collection('families').doc(id);
        const payload = await Fire.doc(ref);
        return payload;
      })
    );
    getFamilyOptions(payloadFamilies);
  };

  React.useEffect(() => {
    const getUser = async () => {
      const fireUser = await Fire.get('users', user.id);

      if (fireUser.families.length) {
        getUserFamilies(fireUser.families);
      }
    };

    if (user) {
      getUser();
    }
  }, [user]);

  return (
    <Box
      sx={{
        marginTop: { xs: '2rem', sm: '3rem' },
        height: 'calc(62% - 2rem)',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        paddingRight: '1rem',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '7px',
          backgroundColor: '#aaa',
        },
      }}
    >
      <CustomLabel
        label="Dans quelle(s) famille(s) voulez-vous partager cette recette ? :"
        marginBottom="1.25rem"
      />

      <FormControl
        sx={{
          width: '100%',
          marginBottom: '1.5rem',
        }}
      >
        <InputLabel id="multiple-families-label">Familles</InputLabel>
        <Select
          labelId="multiple-families-label"
          id="multiple-families"
          multiple
          value={families}
          name="families"
          onChange={(e) => {
            onChange(e);
          }}
          input={
            <OutlinedInput id="select-multiple-families" label="Familles" />
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={
                    familyOptions.find((family) => family.value === value)
                      ?.label
                  }
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {familyOptions.map((family) => (
            <MenuItem key={family.value} value={family.value}>
              {family.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <CustomLabel
        label={
          <Box>
            Voulez-vous rendre cette recette publique ? :
            <Tooltip
              title="Votre recette sera visible par les autres utilisateurs de la plateforme. Vous pourrez donc la partager à un utilisateur qui n'est pas dans votre famille"
              enterTouchDelay={0}
              leaveTouchDelay={5000}
            >
              <IconButton
                size="small"
                sx={{
                  marginLeft: '.5rem',
                }}
              >
                <InfoIcon
                  fontSize="small"
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="isPublic"
          value={isPublic}
          onChange={(e) => {
            onChangeIsPublic(e);
          }}
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Oui"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '1.15rem',
              },
            }}
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Non"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '1.15rem',
              },
            }}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default Step1;

