/* eslint-disable react/no-unescaped-entities */
import { Grid, Typography, Fade } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FamilyType, InvitationType } from '../../../types/Family.type';
import SectionTitle from '../../Templates/Title/SectionTitle/SectionTitle';
import FamilyCard from '../FamilyCard/FamilyCard';
import InvitationCard from '../FamilyCard/InvitationCard';

interface AccountFamiliesProps {
  openFamilyModal: () => void;
  isLoadingFamilies: boolean;
  families: FamilyType[];
  invitations: InvitationType[];
}

const AccountFamilies: React.FC<AccountFamiliesProps> = ({
  openFamilyModal,
  isLoadingFamilies,
  families,
  invitations,
}) => {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingLeft: { xs: 0, md: '5rem' },
        paddingBottom: '4rem',
      }}
    >
      <SectionTitle
        title="Mes familles"
        button={{
          title: 'Créer une famille',
          onClick: () => openFamilyModal(),
        }}
      />
      {isLoadingFamilies ? (
        <FamilyCard isLoadingCard />
      ) : (
        <Fade in={!isLoadingFamilies} timeout={500}>
          <Box
            sx={{
              marginTop: '2rem',
              display: 'grid',
              gridTemplateColumns: {
                xs: '100%',
                sm: '50% 50%',
              },
              gap: '1rem',
              // justifyContent: 'space-between',
              // flexWrap: 'wrap',
              width: '100%',
            }}
          >
            {invitations.map((invitation) => {
              return (
                <InvitationCard
                  family={invitation.family}
                  key={invitation.id}
                  invitationId={invitation.id}
                />
              );
            })}
            {families.length === 0 && invitations.length === 0 ? (
              <Typography
                variant="body2"
                sx={{
                  fontSize: '.9rem',
                  color: 'rgba(0,0,0,.5)',
                }}
              >
                Vous n'êtes dans aucun groupe famille. <br />
                Créez-en un en cliquant sur le bouton ci-dessus ou demandez à
                être ajouté à votre groupe famille !
              </Typography>
            ) : (
              families.map((family) => {
                return <FamilyCard family={family} key={family.id} />;
              })
            )}
          </Box>
        </Fade>
      )}
    </Grid>
  );
};

export default AccountFamilies;

