/* eslint-disable indent */
/* eslint-disable react/no-unescaped-entities */
import { Grid, Typography, Fade } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { RecipeProps } from '../../types/RecipeProps.type';
import RecipeCard from '../Templates/RecipeCard/RecipeCard';
import SectionTitle from '../Templates/Title/SectionTitle/SectionTitle';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/slices/recipe/recipeSlice';

interface RecipesListProps {
  isLoadingRecipes: boolean;
  title?: string;
  recipes: RecipeProps[];
  draftRecipes?: RecipeProps[];
  hideAuthor?: boolean;
}

const RecipesList: React.FC<RecipesListProps> = ({
  isLoadingRecipes,
  recipes,
  title,
  draftRecipes,
  hideAuthor,
}) => {
  const dispatch = useDispatch();

  const onOpenModal = () => {
    dispatch(openModal());
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <SectionTitle
        title={title || 'Mes recettes'}
        button={{
          title: 'Ajouter une recette',
          onClick: () => {
            onOpenModal();
          },
        }}
        justify="left"
      />
      {isLoadingRecipes ? (
        <Box
          sx={{
            marginTop: '2rem',
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(4, 1fr)',
              md: 'repeat(6, 1fr)',
            },
            gap: {
              xs: '2rem',
              sm: '1.5rem',
              md: '2rem',
            },
            width: '100%',
          }}
        >
          <RecipeCard isLoadingCard />
        </Box>
      ) : (
        <Fade in timeout={500}>
          <Box
            sx={{
              marginTop: '2rem',
              display: 'grid',
              gridTemplateColumns:
                recipes?.length === 0
                  ? '1fr'
                  : {
                      xs: 'repeat(2, 1fr)',
                      sm: 'repeat(4, 1fr)',
                      md: 'repeat(6, 1fr)',
                    },
              gap: {
                xs: '2rem',
                sm: '1.5rem',
                md: '2rem',
              },
              width: '100%',
            }}
          >
            {!!draftRecipes?.length &&
              draftRecipes.map((recipe) => {
                return (
                  <RecipeCard
                    hideAuthor={hideAuthor}
                    recipe={recipe}
                    key={recipe.id}
                    draft
                  />
                );
              })}
            {recipes?.length === 0 && draftRecipes?.length === 0 ? (
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1rem',
                  color: 'rgba(0,0,0,.5)',
                }}
              >
                Vous n'avez ajouté aucune recette. <br />
                Ajoutez-en une en cliquant sur le bouton ci-dessus !
              </Typography>
            ) : (
              recipes.map((recipe) => {
                if (!recipe) return null;
                return (
                  <RecipeCard
                    hideAuthor={hideAuthor}
                    recipe={recipe}
                    key={recipe.id}
                  />
                );
              })
            )}
          </Box>
        </Fade>
      )}
    </Grid>
  );
};

export default RecipesList;

