import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Wait from '../../../../images/icons/wait.svg';
import Trash from '../../../../images/icons/trash.svg';

interface PendingMembersProps {
  pendingMembers: string[];
  onDeletePendingMember: (email: string) => void;
  sendInvitEmail: (email: string) => void;
}

const PendingMembers: React.FC<PendingMembersProps> = ({
  pendingMembers,
  onDeletePendingMember,
  sendInvitEmail,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        marginBottom: '1rem',
        paddingTop: '1rem',
        borderTop: '1px solid #ddd',
      }}
    >
      {pendingMembers.map((member, index) => (
        <Box
          key={member}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '.25rem .5rem',
            marginBottom: index === pendingMembers.length - 1 ? 0 : '.5rem',

            '&:hover': {
              '& .WaitingMember': {
                opacity: 0,
              },
              '& .sendInvitWaitingMember': {
                opacity: 1,
                marginRight: '0rem',
              },
              '& .deleteWaitingMember': {
                opacity: 1,
                marginRight: '0rem',
              },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 40,
                height: 40,
                background: '#F4F7F8',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '1rem',
              }}
            />
            <Typography
              variant="h3"
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
                color: 'rgba(31, 32, 38, 0.6)',
              }}
            >
              {member}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1rem',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 3,
                zIndex: 1,
                transition: 'all .2s ease-in-out',
                opacity: 0,
              }}
              className="sendInvitWaitingMember"
            >
              <SendRoundedIcon
                sx={{
                  cursor: 'pointer',
                  transform: 'rotate(-35deg) translateY(-2px)',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    opacity: 0.7,
                  },
                }}
                onClick={() => sendInvitEmail(member)}
              />{' '}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 3,
                cursor: 'pointer',
                zIndex: 1,
                transition: 'all .2s ease-in-out',
                opacity: 0,

                '&:hover img': {
                  opacity: 0.7,
                },
              }}
              className="deleteWaitingMember"
              onClick={() => {
                onDeletePendingMember(member);
              }}
            >
              <img
                src={Trash}
                alt="wait"
                style={{
                  width: '1.25rem',
                  transition: 'all .2s ease-in-out',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 3,
                position: 'absolute',
                right: '0',
                transition: 'all .2s ease-in-out',
                opacity: 1,
              }}
              className="WaitingMember"
            >
              <img
                src={Wait}
                alt="wait"
                style={{
                  width: '1rem',
                }}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default PendingMembers;

