const cfg = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'thekitchenbook.fr',
  databaseURL: 'https://the-kitchen-book-41408.firebaseio.com',
  projectId: 'the-kitchen-book-41408',
  storageBucket: 'gs://the-kitchen-book-41408.appspot.com',
  messagingSenderId: '955706573814',
  appId: '1:955706573814:web:30d1b98906b8e8b5003dd7',
  measurementId: 'G-L7TZ0L7D1M',
};
export default cfg;

