/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Navbar from '../Templates/Navbar/Navbar';
import Box from '@mui/material/Box';
import Fire from '../../services/fire.service';
import { Fade, Grid } from '@mui/material';
import { theme } from '../../theme/_theKitchenBookTheme';
import AccountFamilies from './AccountFamilies/AccountFamilies';
import RecipesList from '../RecipesList/RecipesList';
import CreateFamilyModal from './CreateFamilyModal/CreateFamilyModal';
import { useAlert } from '../../hooks/useAlert';
import { RecipeProps } from '../../types/RecipeProps.type';
import Infos from '../Templates/Infos/Infos';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../redux/slices/user/userSlice';
import { RootState } from '../../redux/store/store';
import {
  FamilyPayload,
  FamilyType,
  InvitationType,
} from '../../types/Family.type';
import GradeModal from './GradeModal/GradeModal';

const Account: React.FC = () => {
  const [createFamilyOpen, setCreateFamilyOpen] = React.useState(false);
  const [gradeModalOpen, setGradeModalOpen] = React.useState(false);
  const [families, setFamilies] = React.useState<FamilyType[]>([]);
  const [userRecipes, setUserRecipes] = React.useState<RecipeProps[]>([]);
  const [userDraftRecipes, setUserDraftRecipes] = React.useState<RecipeProps[]>(
    []
  );
  const [invitations, setInvitations] = React.useState<InvitationType[]>([]);
  const [isLoadingFamilies, setIsLoadingFamilies] = React.useState(true);
  const [isLoadingRecipes, setIsLoadingRecipes] = React.useState(false);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const { user, isLoading: isLoadingUser } = useSelector(
    (state: RootState) => state.userReducer
  );

  const initials = `${Array.from(user?.firstName || '')[0] || ''}${
    Array.from(user?.lastName || '')[0] || ''
  }`;
  const name = `${user?.firstName || ''} ${user?.lastName || ''}`;

  const handleOpenGradeModal = () => {
    setGradeModalOpen(true);
  };

  const sendInvitEmail = async (to, familyName, expeditor) => {
    try {
      await Fire.cloud('sendMail', {
        user: {
          email: to,
        },
        templateId: 2,
        params: {
          familyName: familyName,
          expeditor: {
            firstName: expeditor,
          },
        },
      });
    } catch (e) {
      console.log(e);
      showAlert(
        "Une erreur est survenue lors de l'envoi de l'invitation",
        'error'
      );
      return e;
    }
  };

  const onCreateFamily = async (payload: FamilyPayload) => {
    const newPayload = {
      title: payload.title,
      members: [
        {
          id: user.id,
          username: user.username,
          email: user.email,
          grade: user.grade,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      ],
      pendingMembers: [...payload.members],
      recipes: [],
    };

    await Fire.add('families', newPayload).then(async (res) => {
      await await Fire.update('users', user.id, {
        families: [...user.families, res.id],
      });
      dispatch(
        updateUser({
          families: [...user.families, res.id],
        })
      );

      const promises = payload.members.map(async (member) => {
        invitMember(
          {
            title: payload.title,
            id: res.id,
            recipesLength: 0,
            membersLength: 1,
          },
          member
        );
      });

      await Promise.all(promises);
    });

    showAlert('Votre groupe famille a été créé avec succes !', 'success');
    setCreateFamilyOpen(false);
  };

  const invitMember = async (family, value) => {
    if (!value) return;

    try {
      // send email

      const invitation: InvitationType = {
        family: family,
        user: value,
        expirateAt: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
        expeditor: {
          id: user.id,
          email: user.email,
          username: user.username,
        },
      };

      const invitationPath = await Fire.add('invitations', invitation);
      const invitationId = invitationPath.id;

      const userRef = Fire.store()
        .collection('users')
        .where('email', '==', value);
      const userExists = await Fire.list(userRef);

      if (userExists && userExists.length > 0) {
        const newUser = userExists[0];

        await Fire.update('users', newUser.id, {
          invitations: [...newUser.invitations, invitationId],
        });
      }

      await sendInvitEmail(value, family.title, user.firstName);
    } catch (err) {
      showAlert(
        "Une erreur est survenue lors de l'envoi de l'invitations de " + value,
        'error'
      );
    }
  };

  React.useEffect(() => {
    const getUserFamilies = async (ids: string[]) => {
      setIsLoadingFamilies(true);
      const payloadFamilies = await Promise.all(
        ids.map(async (id) => {
          const ref = Fire.store().collection('families').doc(id);
          const payload = await Fire.doc(ref);

          if (!payload || !payload.members || !payload.members.length) {
            const newPayload = {
              families: user.families.filter((family) => family !== id),
            };
            await Fire.update('users', user.id, newPayload);
            await dispatch(updateUser(newPayload));
            return;
          }

          const members = payload.members.map((member) => member.id);

          if (!members.includes(user.id)) {
            const newPayload = {
              families: user.families.filter((family) => family !== id),
            };
            await Fire.update('users', user.id, newPayload);
            await dispatch(updateUser(newPayload));
          }

          if (payload) return payload;
        })
      );
      // check if user is still in family, if not delete family id from user
      if (!payloadFamilies || !payloadFamilies.length) {
        const newPayload = {
          families: [],
        };

        await Fire.update('users', user.id, newPayload);
        await dispatch(updateUser(newPayload));
        setFamilies([]);

        return;
      }

      const filteredFamilies = payloadFamilies.filter((family) => {
        if (!family) return false;
        const members = family?.members.map((member) => member.id);
        return members.includes(user.id);
      });

      const newPayload = {
        families: filteredFamilies.map((family) => family.id),
      };

      if (filteredFamilies.length !== payloadFamilies.length) {
        await Fire.update('users', user.id, newPayload);
        await dispatch(updateUser(newPayload));
      }
      setFamilies(filteredFamilies);
      setIsLoadingFamilies(false);
    };

    const getUserRecipes = async (ids: string[]) => {
      setIsLoadingRecipes(true);
      try {
        let payloadRecipes = await Promise.all(
          ids.map(async (id) => {
            const ref = Fire.store().collection('recipes').doc(id);
            const payload = await Fire.doc(ref);
            return payload;
          })
        );
        payloadRecipes = await payloadRecipes.filter(
          (recipe) => recipe !== null && recipe !== undefined && recipe !== ''
        );
        setUserRecipes(payloadRecipes);
        setIsLoadingRecipes(false);
      } catch (e) {
        console.log(e);
      }
      setIsLoadingRecipes(false);
    };

    const getUserInvitations = async (invitations) => {
      const payloadInvitations = await Promise.all(
        invitations.map(async (id) => {
          const ref = Fire.store().collection('invitations').doc(id);
          const payload = await Fire.doc(ref);
          if (!payload) {
            const newPayload = {
              invitations: user.invitations.filter(
                (invitation) => invitation !== id
              ),
            };
            await Fire.update('users', user.id, newPayload);
            await dispatch(updateUser(newPayload));
          }
          return payload;
        })
      );
      // filter invitations if null
      const filteredInvitations = payloadInvitations.filter((invitation) => {
        return invitation !== null;
      });

      setInvitations(filteredInvitations);
    };

    if (user) {
      if (user.families?.length > 0) {
        getUserFamilies(user.families);
      } else {
        setFamilies([]);
        setIsLoadingFamilies(false);
      }

      if (user.recipes?.length > 0) {
        getUserRecipes(user.recipes);
      } else {
        setUserRecipes([]);
        setIsLoadingRecipes(false);
      }

      if (user.draftRecipes?.length > 0) {
        setUserDraftRecipes(user.draftRecipes);
      } else {
        setUserDraftRecipes([]);
      }

      if (user.invitations?.length > 0) {
        getUserInvitations(user.invitations);
      } else {
        setInvitations([]);
      }
    }
  }, [user]);

  return (
    <Box>
      <Navbar />
      <Fade in={true} timeout={500}>
        <Grid
          container
          sx={{
            position: 'relative',
            maxWidth: 1350,
            margin: 'auto',
            padding: '4rem 2rem',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
              padding: '3rem 2rem 2rem ',
            },
          }}
        >
          <Infos
            isUser
            initials={initials}
            name={name}
            isLoading={isLoadingUser}
            nbRecipes={userRecipes.length}
            userGrade={user?.grade}
            handleOpenGradeModal={handleOpenGradeModal}
            sx={{
              paddingBottom: '4rem',
            }}
          />
          <AccountFamilies
            openFamilyModal={() => {
              setCreateFamilyOpen(true);
            }}
            isLoadingFamilies={isLoadingFamilies}
            families={families}
            invitations={invitations}
          />
          <RecipesList
            isLoadingRecipes={isLoadingRecipes}
            recipes={userRecipes || []}
            draftRecipes={userDraftRecipes || []}
            hideAuthor
          />
        </Grid>
      </Fade>
      <CreateFamilyModal
        open={createFamilyOpen}
        onClose={() => {
          setCreateFamilyOpen(false);
        }}
        onSubmit={(payload) => {
          onCreateFamily(payload);
        }}
      />
      <GradeModal
        open={gradeModalOpen}
        onClose={() => {
          setGradeModalOpen(false);
        }}
      />
    </Box>
  );
};

export default Account;

