import React from 'react';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { gradesColor, grades } from '../../../../utils/utils';
import Trash from '../../../../images/icons/trash.svg';
import { Typography, Box } from '@mui/material';

interface MembersProps {
  members: any[];
  onDeleteMember: (member: any) => void;
}

const Members: React.FC<MembersProps> = ({ members, onDeleteMember }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        marginBottom: '1rem',
        paddingTop: '1rem',
        borderTop: '1px solid #ddd',
        width: '100%',
      }}
    >
      {members.map((member) => {
        const initials = `${Array.from(member?.firstName || '')[0] || ''}${
          Array.from(member?.lastName || '')[0] || ''
        }`;
        return (
          <Box
            key={member.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '.5rem .5rem',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '90%',
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  background: '#F4F7F8',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '1rem',
                  aspectRatio: '1',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: 15,
                      sm: 18,
                    },
                    fontFamily: 'Poetsen One',
                    color: 'text.primary',
                    textTransform: 'uppercase',
                  }}
                >
                  {initials}
                </Typography>
              </Box>
              <WorkspacePremiumIcon
                sx={{
                  marginRight: '.5rem',
                  color: gradesColor[member?.grade],
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 400,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                {grades[member?.grade]} {member?.username}
                {' - '}
                {member.email}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 3,
                cursor: 'pointer',
              }}
              onClick={() => onDeleteMember(member)}
            >
              <img
                src={Trash}
                alt="wait"
                style={{
                  width: '1.25rem',
                }}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Members;

