import React from 'react';
import { Grid } from '@mui/material';
import RecipeCard from '../Templates/RecipeCard/RecipeCard';

const RecipeLoading = () => {
  return (
    <Grid
      item
      xs={5}
      sm={4}
      md={3}
      key={`container-recipe-test`}
      sx={{
        paddingLeft: 'inherit',
      }}
    >
      <RecipeCard isLoadingCard />
    </Grid>
  );
};

export default RecipeLoading;

