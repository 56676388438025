import React from 'react';
import { Grid } from '@mui/material';
import Header from './Header/Header';
import HowItWorks from './HowItWorks/HowItWorks';
import HomeCards from './HomeCards/HomeCards';
import Banner from './Banner/Banner';
import Footer from '../Templates/Footer/Footer';

const HomeScreen = (): JSX.Element => {
  return (
    <Grid container>
      <Header />
      <HowItWorks />
      <HomeCards />
      <Banner />
      <Footer />
    </Grid>
  );
};

export default HomeScreen;

