import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import CustomLabel from '../../Templates/Inputs/CustomLabel';
import CustomOutlinedInput from '../../Templates/Inputs/CustomOutlinedInput';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';

interface Step1Props {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeIngredients: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  title: string;
  ingredients: Ingredient[];
  addIngredient: () => void;
  removeIngredient: (index: number) => void;
  ingredientsError: string;
  onChangePortions: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleReorderIngredients: (ingredients: Ingredient[]) => void;
  portions: {
    quantity: string;
    measure: string;
  };
  portionsError?: any;
}

type Ingredient = {
  name: string;
  quantity: number;
  unit: string;
  id: string;
};

const Step1: React.FC<Step1Props> = ({
  ingredients,
  onChangeIngredients,
  addIngredient,
  removeIngredient,
  ingredientsError,
  onChangePortions,
  portions,
  portionsError,
  handleReorderIngredients,
}) => {
  const ingredientsRef = React.useRef<HTMLDivElement>(null);
  const [ingredientsList, setIngredientsList] = React.useState([]);

  React.useEffect(() => {
    setIngredientsList(ingredients);
  }, [ingredients]);

  const onAddIngredient = () => {
    addIngredient();
    setTimeout(() => {
      ingredientsRef.current?.scrollTo(
        0,
        ingredientsRef.current?.scrollHeight * 2
      );
    }, 100);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(ingredientsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleReorderIngredients(items);
    setIngredientsList(items);
  }

  return (
    <>
      <Box
        sx={{
          marginTop: { xs: '2rem', sm: '3rem' },
          height: 'calc(58% - 2rem)',
          overflow: 'auto',
          scrollBehavior: 'smooth',
          paddingRight: ingredients.length > 1 ? '.5rem' : 0,
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: '#F5F5F5',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: '#aaa',
          },
        }}
        ref={ingredientsRef}
      >
        <CustomLabel label="Nombre de portions :" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '1.5rem',
          }}
        >
          <CustomOutlinedInput
            placeholder="Ex: 2"
            name="quantity"
            type="number"
            onChange={(e) => onChangePortions(e)}
            error={!!portionsError && !portions.quantity}
            defaultValue={portions.quantity}
            marginBottom="0"
            sx={{
              width: '20%',

              '& fieldset': {
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                borderRightColor: 'transparent',
              },
            }}
          />
          <CustomOutlinedInput
            placeholder="verrines, personnes, etc..."
            fullWidth
            name="measure"
            type="text"
            onChange={(e) => onChangePortions(e)}
            marginBottom="0"
            error={!!portionsError && !portions.measure}
            defaultValue={portions.measure}
            sx={{
              '& fieldset': {
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
              },
            }}
          />
        </Box>
        <CustomLabel label="Ingrédients :" />
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="ingredients-draggable">
            {(provided) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  width: '100%',
                  paddingTop: '2px',
                  marginBottom: '1rem',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {ingredientsList.map((ingredient, index) => (
                  <Draggable
                    key={ingredient.id}
                    draggableId={ingredient.id}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        key={ingredient.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '1rem',
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Box {...provided.dragHandleProps}>
                          <DragHandleIcon sx={{ marginRight: '1rem' }} />
                        </Box>
                        <CustomOutlinedInput
                          placeholder="Ex: 10"
                          name="quantity"
                          type="number"
                          onChange={(e) => onChangeIngredients(e, index)}
                          defaultValue={ingredient.quantity || ''}
                          marginBottom="0"
                          sx={{
                            width: '20%',
                            '& fieldset': {
                              borderTopRightRadius: '0px',
                              borderBottomRightRadius: '0px',
                            },
                          }}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        />
                        <CustomOutlinedInput
                          placeholder="cl"
                          name="unit"
                          type="text"
                          onChange={(e) => onChangeIngredients(e, index)}
                          defaultValue={ingredient.unit}
                          marginBottom="0"
                          sx={{
                            width: '25%',
                            '& fieldset': {
                              borderLeftColor: 'transparent',
                              borderRightColor: 'transparent',
                              borderRadius: '0px',
                            },
                          }}
                        />

                        <CustomOutlinedInput
                          placeholder="de lait"
                          name="name"
                          type="text"
                          onChange={(e) => onChangeIngredients(e, index)}
                          defaultValue={ingredient.name}
                          marginBottom="0"
                          sx={{
                            width: '55%',
                            '& fieldset': {
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px',
                            },
                          }}
                        />

                        {ingredients.length > 1 && (
                          <DeleteIcon
                            sx={{
                              marginLeft: '.5rem',
                              fontSize: '1.25rem',
                              color: 'error.main',
                              cursor: 'pointer',
                            }}
                            onClick={() => removeIngredient(index)}
                          />
                        )}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}

                {ingredientsError && ingredients.length === 1 && (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '.9rem',
                      fontWeight: 400,
                      marginBottom: '1rem',
                      color: 'error.main',
                    }}
                  >
                    {typeof ingredientsError === 'string'
                      ? ingredientsError
                      : null}
                  </Typography>
                )}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: 'auto',
        }}
      >
        <ButtonFilled
          onClick={() => {
            onAddIngredient();
          }}
          grey
          title="Ajoutez un ingrédient"
        />
      </Box>
    </>
  );
};

export default Step1;

