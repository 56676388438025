import React from 'react';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import CustomLabel from '../../Templates/Inputs/CustomLabel';
import CustomOutlinedInput from '../../Templates/Inputs/CustomOutlinedInput';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';

interface Step1Props {
  onChangeSteps: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  handleReorderSteps: (steps: Step[]) => void;
  steps: Step[];
  addStep: () => void;
  addSteps: (steps: string[]) => void;
  removeStep: (index: number) => void;
  stepsError: any;
}

type Step = {
  name: string;
  id: string;
};

const Step1: React.FC<Step1Props> = ({
  steps,
  onChangeSteps,
  handleReorderSteps,
  addStep,
  removeStep,
  stepsError,
  addSteps,
}) => {
  const stepsRef = React.useRef<HTMLDivElement>(null);
  const [stepType, setStepType] = React.useState<'steps' | 'textarea'>(
    undefined
  );
  const [stepsList, setStepsList] = React.useState<Step[]>([]);

  const onAddStep = () => {
    addStep();
    setTimeout(() => {
      stepsRef.current?.scrollTo(0, stepsRef.current?.scrollHeight);
    }, 100);
  };

  const handleChangeSteps = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    onChangeSteps(e, index);
    if (e.target.value.length > 0) {
      setStepType('steps');
    }
  };

  const handleChangeTextarea = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.length < 1) return;
    const steps = e.target.value.split('\n').filter((step) => step !== '');
    await addSteps(steps);
    setStepType('steps');
  };

  React.useEffect(() => {
    if (steps.length === 1 && steps[0].name.length === 0) {
      setStepType(undefined);
    } else if (steps[0].name.length !== 0) {
      setStepType('steps');
    } else if (steps.length > 1) {
      setStepType('steps');
    }
  }, [steps]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(stepsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    handleReorderSteps(items);
    setStepsList(items);
  }

  React.useEffect(() => {
    setStepsList(steps);
  }, [steps]);

  return (
    <>
      <Box
        sx={{
          marginTop: { xs: '2rem', sm: '3rem' },
          height: 'calc(62% - 2rem)',
          overflow: 'auto',
          scrollBehavior: 'smooth',
          paddingRight: steps.length > 1 ? '1rem' : 0,
          '&::-webkit-scrollbar': {
            width: '6px',
            backgroundColor: '#F5F5F5',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            backgroundColor: '#aaa',
          },
        }}
        ref={stepsRef}
      >
        {stepType !== 'textarea' && (
          <Box>
            <CustomLabel label="Étapes :" />
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="steps-draggable">
                {(provided) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                      width: '100%',
                      paddingTop: '2px',
                      marginBottom: '.5rem',
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {stepsList.map((step, index) => (
                      <Draggable
                        key={step.id}
                        draggableId={step.id}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            key={step.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '1rem',
                              width: '100%',
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <Box {...provided.dragHandleProps}>
                              <DragHandleIcon sx={{ marginRight: '1rem' }} />
                            </Box>
                            <CustomOutlinedInput
                              placeholder="ex : Épluchez les oignons et découpez-les en petits cubes. "
                              name="name"
                              multiline
                              minRows={2}
                              type="text"
                              onChange={(e) => handleChangeSteps(e, index)}
                              defaultValue={step.name}
                              marginBottom="0"
                            />

                            {steps.length > 1 && (
                              <DeleteIcon
                                sx={{
                                  marginLeft: '.5rem',
                                  fontSize: { xs: '1.75rem', sm: '1.25rem' },
                                  color: 'error.main',
                                  cursor: 'pointer',
                                }}
                                onClick={() => removeStep(index)}
                              />
                            )}
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
        {stepType !== 'steps' && (
          <CustomOutlinedInput
            label="Ou copie-colle ta recette ici :"
            placeholder="ex : Épluchez les oignons et découpez-les en petits cubes. "
            multiline
            minRows={4}
            maxRows={13}
            onChange={(e) => handleChangeTextarea(e)}
            defaultValue={steps[0].name}
            name="name"
            errorMessage={
              stepsError && steps[0].name == '' ? stepsError[0].name : ''
            }
          />
        )}
      </Box>
      {stepType === 'steps' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            marginTop: 'auto',
          }}
        >
          <ButtonFilled
            onClick={() => {
              onAddStep();
            }}
            grey
            title="Ajoutez une étape"
          />
        </Box>
      )}
    </>
  );
};

export default Step1;

