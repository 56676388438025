/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import { getAnalytics } from 'firebase/analytics';
import cfg from '../keys/firebase';

export default class Fire {
  // Initialize Firebase
  static init() {
    if (firebase.apps.length) return;
    const app = firebase.initializeApp(cfg);
    getAnalytics(app);
    firebase.firestore().settings({
      experimentalForceLongPolling: true,
      merge: true,
      experimentalAutoDetectLongPolling: false,
    });
  }

  // Retrieve base firestore
  static store() {
    return firebase.firestore();
  }

  // Retrieve base messaging
  static messaging() {
    return firebase.messaging();
  }

  // Retrieve batch
  static batch() {
    return Fire.store().batch();
  }

  // Retrieve base auth
  static auth() {
    return firebase.auth();
  }

  // Retrieve base storage
  static storage() {
    return firebase.storage();
  }

  static providers() {
    return {
      google: new firebase.auth.GoogleAuthProvider(),
    };
  }

  static ArrayRemove = firebase.firestore.FieldValue.arrayRemove;

  static async cloud<T = any>(name: string, data?: any): Promise<T> {
    const functions = firebase.functions();

    if (process.env.NODE_ENV === 'development') {
      console.log('USING LOCAL FUNCTIONS');
      functions.useEmulator('localhost', 5001);
    }
    const callable = functions.httpsCallable(name);
    const res: any = await callable(data);
    return res.data;
  }

  // Reauth
  static async reauthEmail(email: any, password: any) {
    const user = Fire.auth().currentUser;
    if (!user) return null;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    return user.reauthenticateWithCredential(credential);
  }

  // Upload a file to Storage
  static async uploadFile(location: any, uri: any) {
    // Retrieve Blob
    const res = await fetch(uri);
    const blob = await res.blob();
    // Send it to Firebase Storage
    const store = Fire.storage().ref();
    const ref = store.child(location);
    const uploaded = await ref.put(blob);
    // get file name
    const name = uploaded.metadata.name;
    // Retrieve persistent URL
    const url = await uploaded.ref.getDownloadURL();
    return {
      url,
      name: name,
    };
  }

  // Shortcuts
  static async doc(ref: any) {
    const doc: any = await ref.get();
    if (!doc.exists) return null;
    return {
      id: doc.id,
      ...doc.data(),
    };
  }
  static async list(ref: any) {
    const arr: any[] = [];
    const snap = await ref.get();
    if (snap.empty) return arr;
    if (snap.size) {
      snap.forEach((doc: any) => {
        if (!doc.exists) return null;
        arr.push({
          id: doc.id,
          ...doc.data(),
        });
      });
    } else {
      arr.push({
        id: snap.id,
        ...snap.data(),
      });
    }
    return arr;
  }

  // Shortcuts

  static set = async (collection: any, id: any, data: any) => {
    return Fire.store().collection(collection).doc(id).set(data);
  };
  static update = async (collection: any, id: any, data: any) => {
    return Fire.store().collection(collection).doc(id).update(data);
  };

  static add = async (collection: any, data: any) => {
    return Fire.store().collection(collection).add(data);
  };

  static get = async (collection: any, id: any) => {
    return Fire.doc(Fire.store().collection(collection).doc(id));
  };

  static delete = async (collection: any, id: any) => {
    return Fire.store().collection(collection).doc(id).delete();
  };

  static listAll = async (collection: any) => {
    return Fire.list(Fire.store().collection(collection));
  };
}

