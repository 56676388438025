import { createSlice } from '@reduxjs/toolkit';
import { DraftRecipeProps } from '../../../types/RecipeProps.type';
import { uniqueId } from '../../../utils/utils';

export const initialRecipe: DraftRecipeProps = {
  id: null,
  title: '',
  introduction: '',
  portions: {
    quantity: null,
    measure: '',
  },
  categories: [],
  cookingTime: null,
  preparationTime: null,
  ingredients: [
    {
      quantity: '',
      unit: '',
      name: '',
      id: 'firstIngredient',
    },
  ],
  image: '',
  imageName: '',
  recipe: [
    {
      name: '',
      id: 'firstStep',
    },
  ],
  families: [],
  isPublic: true,
  author: null,
};

const initialState = {
  recipe: initialRecipe,
  isModalOpen: false,
  isUpdating: false,
};

const userSlice = createSlice({
  name: 'recipeReducer',
  initialState,
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
      if (state.recipe.id) {
        state.recipe = initialRecipe;
        state.isUpdating = false;
      }
    },
    updateRecipe: (state, action) => {
      if (action.payload.isUpdating === true) {
        state.isUpdating = true;
      }

      const newRecipe = {
        ...state.recipe,
        ...action.payload.recipe,
      };
      delete newRecipe.date;
      delete newRecipe.dateString;
      if (action.payload.recipe.filters) {
        newRecipe.categories = action.payload.recipe.filters;
        delete newRecipe.filters;
      }
      if (typeof action.payload.recipe.recipe[0] === 'string') {
        newRecipe.recipe = action.payload.recipe.recipe.map((step) => {
          const newId = uniqueId();
          return { name: step, id: newId };
        });
      }
      state.recipe = newRecipe;
    },
  },
});

export const { openModal, closeModal, updateRecipe } = userSlice.actions;

export default userSlice.reducer;

