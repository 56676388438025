import React from 'react';
import Navbar from '../../Templates/Navbar/Navbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Grid, Box, Typography, useTheme, Fade } from '@mui/material';
import HeaderImage from '../../../images/Accueil/HeaderImage.jpg';
import HeaderImageDesktop from '../../../images/Accueil/HeaderImageDesktop.jpg';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import { LoginContext } from '../../../contexts/LoginContext';

const Header: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { openLogin } = React.useContext(LoginContext);

  return (
    <Grid
      container
      sx={{
        position: 'relative',
      }}
    >
      <Fade in={true} timeout={500}>
        <Grid item xs={12}>
          <Navbar transparent={!mobile} />
        </Grid>
      </Fade>
      <Fade in={true} timeout={500}>
        <Grid
          container
          sx={{
            position: 'relative',
            maxWidth: 1350,
            margin: 'auto',
            padding: '6rem 3.5rem 4rem 3.5rem',
            [theme.breakpoints.down('sm')]: {
              padding: '2rem 2rem 4rem',
            },
          }}
        >
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                paddingRight: { xs: 0, sm: '3rem' },
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    md: '3.5rem',
                    sm: '2.25rem',
                    xs: '2.4rem',
                  },
                  fontFamily: 'Poetsen One',
                  fontWeight: 500,
                  textAlign: 'left',
                  color: theme.palette.text.primary,
                  marginBottom: '2rem',
                  maxWidth: '500px',
                  lineHeight: { md: '122%', sm: 'normal' },
                }}
              >
                Partagez vos recettes de cuisine en famille !
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { md: '1.15rem', sm: '1rem', xs: '1.3rem' },
                  fontWeight: 500,
                  textAlign: 'left',
                  color: theme.palette.text.primary,
                  maxWidth: '600px',
                }}
              >
                Découvrez un espace numérique 100% gratuit dédié à la
                préservation de vos
                <span className="red"> recettes familiales</span>. Donnez vie
                aux plats qui vous évoquent
                <span className="red"> souvenirs et traditions.</span>
              </Typography>
              <ButtonFilled
                title="Commencez maintenant !"
                sx={{
                  marginTop: '3rem',
                  display: {
                    xs: 'none',
                    sm: 'block',
                  },
                }}
                onClick={() => openLogin('sign-up')}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: {
                xs: '3rem',
                md: 0,
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '110%',
                },
                transform: {
                  xs: 'translateX(0)',
                  sm: 'translateX(10%)',
                },
              }}
            >
              <img
                src={mobile ? HeaderImage : HeaderImageDesktop}
                style={{
                  width: '100%',
                  borderRadius: '5px',
                }}
              />
            </Box>
            <ButtonFilled
              title="Commencez maintenant !"
              sx={{
                marginTop: '3rem',
                display: {
                  xs: 'block',
                  sm: 'none',
                },
              }}
              onClick={() => openLogin('sign-up')}
            />
          </Grid>
        </Grid>
      </Fade>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'block',
          },
          top: 0,
          left: 0,
          right: '25%',
          bottom: 0,
          backgroundColor: 'red',
          position: 'absolute',
          zIndex: -1,
          background:
            'linear-gradient(238deg, rgba(239, 35, 60, 0.05) 10.82%, rgba(244, 247, 248, 0.05) 99.33%)',
        }}
      ></Box>
    </Grid>
  );
};

export default Header;

