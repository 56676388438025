/* eslint-disable indent */
import React, { useState } from 'react';
import {
  Typography,
  InputAdornment,
  IconButton,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { Fire } from '../../../services';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ButtonFilled from '../Buttons/ButtonFilled';
import Box from '@mui/material/Box';
import Logo from '../../../images/Misc/Logo.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import {
  option,
  ValuesLogin,
  ValuesSignup,
  LoginToTitle,
} from '../../../types/Login.type';
import { useDispatch, useSelector } from 'react-redux';
import { login, setLoading } from '../../../redux/slices/user/userSlice';
import CustomOutlinedInput from '../Inputs/CustomOutlinedInput';

const steps = ['étape 1', 'étape 2'];

const loginToTitle: LoginToTitle = {
  login: 'Connexion',
  'sign-up': 'Inscription',
};

type LoginProps = {
  option: option;
  setOption: (option: option) => void;
  handleClose: () => void;
};

const Login: React.FC<any> = (props: LoginProps) => {
  const { option, setOption, handleClose } = props;
  const [step, setStep] = useState<number>(0);
  const [valuesLogin, setValuesLogin] = useState<ValuesLogin>({
    email: '',
    password: '',
    showPassword: false,
  });
  const [valuesSignup, setValuesSignup] = useState<ValuesSignup>({
    email: '',
    username: '',
    password: '',
    showPassword: false,
    firstName: '',
    lastName: '',
  });
  const [registering, setRegistering] = useState(false);
  const [loginIn, setLoginIn] = useState(false);
  const [message, setMessage] = React.useState<string>('');
  const [typeMessage, setTypeMessage] = useState<'error' | 'success' | 'info'>(
    'error'
  );
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: any) => state.userReducer);

  const loginUser = async () => {
    const { email, password } = valuesLogin;

    if (!email?.trim().length) {
      handleMessage('error', 'Veuillez saisir votre email de connexion');
      return;
    }
    if (!password?.trim().length) {
      handleMessage('error', 'Veuillez saisir votre mot de passe');
      return;
    }

    setLoginIn(true);
    try {
      await dispatch(setLoading(true));
      await Fire.auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (values) => {
          const user = await Fire.get('users', values.user.uid);
          await dispatch(login(user));
        });
      handleClose();
    } catch (err) {
      const error = err as any;
      switch (error.code) {
        case 'auth/wrong-password':
          handleMessage('error', 'Identifiants invalides');
          break;
        case 'auth/user-not-found':
          handleMessage('error', 'Identifiants invalides');
          break;
        case 'auth/invalid-email':
          handleMessage('error', "L'email entré est invalide");
          break;
        default:
          handleMessage('error', 'Une erreur est survenue');
          break;
      }
    }
    setLoginIn(false);
  };

  const register = async () => {
    const { email, username, password } = valuesSignup;
    // const { source } = document.location
    if (!email?.trim().length) {
      handleMessage('error', 'Veuillez saisir votre email de connexion');
      setStep(1);
      return;
    } else if (!username?.trim().length) {
      handleMessage('error', 'Veuillez saisir votre pseudo');
      setStep(1);
      return;
    }

    await dispatch(setLoading(true));

    try {
      setRegistering(true);
      await Fire.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (values) => {
          // get invitations
          const invitationsRef = Fire.store()
            .collection('invitations')
            .where('user', '==', email);
          const invitations = await Fire.list(invitationsRef);

          const newUser = {
            id: values.user.uid,
            email: values.user.email,
            username: valuesSignup.username,
            firstName: valuesSignup.firstName,
            lastName: valuesSignup.lastName,
            invitations:
              invitations && !!invitations.length
                ? [...invitations.map((invitation) => invitation.id)]
                : [],
            families: [],
            recipes: [],
            draftRecipes: [],
            grade: 'apprentice',
          };
          await Fire.set('users', values.user.uid, newUser);
          dispatch(login(newUser));
          handleMessage('success', 'Votre compte a bien été créé !');
        });
      setRegistering(false);
      handleClose();
    } catch (err) {
      const error = err as any;
      setRegistering(false);

      switch (error.code) {
        case 'auth/email-already-in-use':
          handleMessage(
            'error',
            "L'email entré est déjà utilisé. Connectez-vous."
          );
          setStep(1);
          break;
        case 'auth/invalid-password':
          handleMessage('error', 'Identifiants invalides');
          setStep(1);
          break;
        case 'auth/user-not-found':
          handleMessage('error', 'Identifiants invalides');
          setStep(1);
          break;
        case 'auth/invalid-email':
          handleMessage('error', "L'email entré est invalide");
          setStep(1);
          break;
        case 'auth/weak-password':
          handleMessage('error', 'Mot de passe trop faible');
          setStep(2);
          break;
        default:
          handleMessage('error', 'Une erreur est survenue');
          break;
      }
    }

    await dispatch(setLoading(false));
  };

  const handleLogin = (categ: 'email' | 'password', value: string) => {
    setValuesLogin((prev: ValuesLogin) => {
      return { ...prev, [categ]: value };
    });
  };
  const handleSignup = (
    categ: 'username' | 'password' | 'email' | 'firstName' | 'lastName',
    value: string
  ) => {
    setValuesSignup((prev: ValuesSignup) => {
      return { ...prev, [categ]: value };
    });
  };

  const handleClickShowPassword = (type: option) => {
    type === 'login'
      ? setValuesLogin((prev) => {
          return { ...prev, showPassword: !prev.showPassword };
        })
      : setValuesSignup((prev) => {
          return { ...prev, showPassword: !prev.showPassword };
        });
  };

  const handleMessage = (type: 'error' | 'success', errorMessage: string) => {
    setTypeMessage(type);
    setMessage(errorMessage);
  };

  const changeStep = (step: number) => {
    setStep(step);
  };

  return (
    <Box
      sx={{
        pointerEvents: 'all',
        background: 'white',
        padding: '1rem 1rem 2rem',
        maxWidth: '600px',
        width: '95vw',
        borderRadius: 9,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box width="fit-content" marginLeft="auto">
        <CloseRoundedIcon
          color="primary"
          onClick={() => handleClose()}
          style={{ cursor: 'pointer' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textTransform: 'capitalize',
          marginBottom: '1rem',
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{
            width: '4rem',
          }}
        />
        <Typography
          variant="h1"
          component="span"
          sx={{
            fontFamily: 'Poetsen One',
            color: 'primary.main',
            marginLeft: '2rem',
            fontWeight: 400,
            fontSize: '2.5rem',
          }}
        >
          {loginToTitle[option]}
        </Typography>
      </Box>
      {option === 'login' ? (
        <Box display="flex" flexDirection="column" width="90%" marginTop="1rem">
          <CustomOutlinedInput
            label="Ton email"
            name="title"
            onChange={(e) => handleLogin('email', e.target.value)}
            value={valuesLogin.email}
            type="email"
            sx={{
              marginBottom: '1.5rem',
            }}
          />

          <CustomOutlinedInput
            name="title"
            label="Ton mot de passe"
            onChange={(e) => handleLogin('password', e.target.value)}
            value={valuesLogin.password}
            type={valuesLogin.showPassword ? 'text' : 'password'}
            onKeyDown={loginUser}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword('login')}
                  edge="end"
                >
                  {valuesLogin.showPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />

          <Box marginTop={message ? '1rem' : '0'}>
            {message !== '' && message !== ' ' && (
              <Alert
                severity={typeMessage}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1">{message}</Typography>
              </Alert>
            )}
          </Box>
          <ButtonFilled
            disabled={loginIn}
            margin="2rem auto 0 "
            title={loginIn ? 'Chagement...' : 'Je me connecte'}
            onClick={() => loginUser()}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <Stepper
              activeStep={step}
              sx={{
                width: '90%',
                maxWidth: 200,
                '& .MuiStep-root': {
                  padding: '0px !important',
                },

                '& .MuiStepConnector-line': {
                  borderColor: '#EF233C',
                },
              }}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => changeStep(index)}
                  sx={{
                    cursor: 'pointer', // hide label
                  }}
                >
                  <StepLabel
                    sx={{
                      cursor: 'pointer !important',
                      '& .MuiStepLabel-label': {
                        display: 'none',
                      },
                      '& .MuiStepLabel-iconContainer': {
                        padding: '0',
                      },
                      '& .MuiSvgIcon-root': {
                        width: { xs: '3rem', sm: '2.5rem' },
                        height: { xs: '3rem', sm: '2.5rem' },
                        padding: '0',
                        // not completed
                        '& text': {
                          fontFamily: 'Poetsen One',
                          fontSize: { xs: '1.25rem', sm: '1rem' },
                        },
                        '&:not(.Mui-active, .Mui-completed) ': {
                          color: 'white',
                          border: '1px solid #EF233C',
                          borderRadius: '50%',
                          '& text': {
                            fill: '#EF233C',
                          },
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {step === 0 ? (
            <Box
              sx={{
                marginTop: '2rem',
              }}
            >
              <CustomOutlinedInput
                label="Ton prénom"
                value={valuesSignup.firstName}
                type="text"
                onChange={(e) => handleSignup('firstName', e.target.value)}
                name="firstName"
                marginBottom="1rem"
              />
              <CustomOutlinedInput
                label="Ton nom de famille"
                value={valuesSignup.lastName}
                type="text"
                onChange={(e) => handleSignup('lastName', e.target.value)}
                name="lastName"
                marginBottom="1rem"
              />
              <CustomOutlinedInput
                label="Ton pseudo de chef"
                value={valuesSignup.username}
                type="text"
                onChange={(e) => handleSignup('username', e.target.value)}
                name="username"
                marginBottom="1rem"
              />
            </Box>
          ) : (
            <Box
              sx={{
                marginTop: '2rem',
              }}
            >
              <CustomOutlinedInput
                label="Ton email de chef"
                placeholder="phiphi.etchebest@gmail.com"
                value={valuesSignup.email}
                type="email"
                onChange={(e) => handleSignup('email', e.target.value)}
              />
              <CustomOutlinedInput
                label="Ton mot de passe"
                type={valuesSignup.showPassword ? 'text' : 'password'}
                value={valuesSignup.password}
                onChange={(e) => handleSignup('password', e.target.value)}
                sx={{
                  '&>.MuiOutlinedInput-input': {
                    paddingTop: '10.5px',
                    paddingBottom: '10.5px',
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => handleClickShowPassword('sign-up')}
                      edge="end"
                    >
                      {valuesSignup.showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          )}
          <Box marginTop={message ? '1rem' : '0'}>
            {message !== '' && message !== ' ' && (
              <Alert severity={typeMessage}>{message}</Alert>
            )}
          </Box>
          <ButtonFilled
            margin="2rem auto 0 "
            title={
              isLoading
                ? 'Chargement...'
                : step === 0
                ? 'Etape suivante'
                : 'Je rejoins les cuistos'
            }
            onClick={() => {
              step === 0 ? setStep(1) : register();
            }}
            disabled={registering}
          />
        </Box>
      )}
      <Box width="90%" margin="1.5rem auto 0" textAlign="center">
        <Typography variant="body1" component="span">
          <Box fontSize="1rem">
            Tu {option === 'login' ? 'ne fais pas encore' : 'fais déjà'} partie
            de la famille ?<br />
            {option === 'login' ? 'Rejoins-nous' : 'Connecte-toi'} en{' '}
            <Typography
              component="span"
              sx={{
                cursor: 'pointer',
                fontWeight: 600,
              }}
              onClick={() =>
                setOption(option === 'sign-up' ? 'login' : 'sign-up')
              }
            >
              <Box
                fontSize="1rem"
                display="inline"
                sx={{
                  transition: 'color .2s ease-in-out',
                  color: 'primary.main',
                  '&:hover': {
                    color: 'primary.dark',
                  },
                }}
              >
                cliquant ici !
              </Box>
            </Typography>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
export default Login;

