import { createApi } from 'unsplash-js';
import nodeFetch from 'node-fetch';

const unsplash = createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_API_KEY,
  fetch: nodeFetch,
});

const request = async (query: string) => {
  const response = await unsplash.search.getPhotos({
    query: query,
    perPage: 30,
  });

  return response;
};

export const useUnsplash = () => {
  const queryImages = async (query: string) => {
    try {
      const response = await request(query);
      return response.response.results;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const triggerDownload = (url: string) => {
    unsplash.photos.trackDownload({ downloadLocation: url });
  };

  return { queryImages, triggerDownload };
};

