import { Typography, Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import CustomLabel from '../../Templates/Inputs/CustomLabel';
import CustomOutlinedInput from '../../Templates/Inputs/CustomOutlinedInput';

interface Step1Props {
  onChangeCategories: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  categories: string[];
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;

  preparationTime: number;
  cookingTime: number;
  CATEGORIES: string[];
  categoriesError?: any;
  preparationTimeError?: any;
  cookingTimeError?: any;
}

const Step1: React.FC<Step1Props> = ({
  onChangeCategories,
  categories,
  onChange,

  preparationTime,
  cookingTime,
  CATEGORIES,
  categoriesError,
  preparationTimeError,
  cookingTimeError,
}) => {
  return (
    <Box
      sx={{
        marginTop: { xs: '2rem', sm: '3rem' },
        height: 'calc(62% - 2rem)',
        overflowX: 'hidden',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
        paddingRight: '1rem',
        '&::-webkit-scrollbar': {
          width: '6px',
          height: '6px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '7px',
          backgroundColor: '#aaa',
        },
      }}
    >
      <CustomLabel label="Catégorie(s) :" />
      <Box
        sx={{
          marginBottom: '1rem',
          display: 'grid',
          gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'repeat(4, 1fr)' },
        }}
      >
        {CATEGORIES.map((category, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={categories.includes(category)}
                onChange={(e) => onChangeCategories(e)}
                name={category}
                value={category}
                sx={{
                  color: 'primary.main',
                  '&.Mui-checked': {
                    color: 'primary.main',
                  },
                }}
              />
            }
            label={category}
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '1rem',
                fontWeight: 500,
              },
            }}
          />
        ))}
        {categoriesError && (
          <Typography
            variant="body1"
            sx={{
              fontSize: '.9rem',
              fontWeight: 400,
              marginBottom: '1rem',
              color: 'error.main',
            }}
          >
            {categoriesError || null}
          </Typography>
        )}
      </Box>
      <CustomOutlinedInput
        label="Temps de préparation (en minutes) :"
        placeholder="Exemple : 15"
        name="preparationTime"
        type="number"
        onChange={(e) => onChange(e)}
        error={!!preparationTimeError && !preparationTime}
        errorMessage={
          preparationTimeError && !preparationTime ? preparationTimeError : null
        }
        defaultValue={preparationTime?.toString() || ''}
      />
      <CustomOutlinedInput
        label="Temps de cuisson (en minutes) :"
        placeholder="Exemple : 20"
        name="cookingTime"
        type="number"
        onChange={(e) => onChange(e)}
        error={!!cookingTimeError && !cookingTime}
        errorMessage={
          cookingTimeError && !cookingTime ? cookingTimeError : null
        }
        defaultValue={cookingTime?.toString() || ''}
      />
    </Box>
  );
};

export default Step1;

