import {
  Grade,
  Grades,
  SmallGrades,
  User,
  GradesColors,
  StepsGrades,
} from '../types/User.type';

export const uniqueId = (): string => {
  const CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let autoId = '';

  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

export const grades: Grades = {
  apprentice: 'Apprenti cuisto',
  commis: 'Commis de cuisine',
  'sous-chef': 'Sous-chef',
  chef: 'Chef étoilé',
};

export const smallGrades: SmallGrades = {
  apprentice: 'Apprenti',
  commis: 'Commis',
  'sous-chef': 'Sous-chef',
  chef: 'Chef',
};

export const gradesColor: GradesColors = {
  apprentice: '#EF233C',
  commis: '#CD7421',
  'sous-chef': '#8489B9',
  chef: '#D4AF37',
};

export const STEPS_GRADES: StepsGrades[] = [
  {
    title: grades.apprentice,
    description:
      "Vous commencez votre aventure en tant qu’Apprenti Cuisto, prêt à <span class='red'>apprendre et à explorer</span> les délices de la cuisine familiale.",
    grade: 'apprentice',
    nbRecipesRequired: 0,
  },
  {
    title: grades.commis,
    description:
      "Devenez un fier Commis de Cuisine en <span class='red'>publiant plus de 10 recettes</span>.",
    grade: 'commis',
    nbRecipesRequired: 10,
  },
  {
    title: grades['sous-chef'],
    description:
      "Pour les vrais pros de la cuisine, nous avons réservé le titre de Chef cuisto. Vous n'aurez qu'à <span class='red'>publier plus de 20 recettes</span>.",
    grade: 'sous-chef',
    nbRecipesRequired: 20,
  },
  {
    title: grades.chef,
    description:
      "Enfin, il y a le grade ultime : Chef Étoilé. Vous devrez <span class='red'>publier plus de 50 recettes</span> pour devenir la star des fourneaux dans votre propre famille",
    grade: 'chef',
    nbRecipesRequired: 50,
  },
];

export const getUserGrade = (user: User): Grade => {
  if (!user.recipes) return 'apprentice';
  if (!user.recipes.length) return 'apprentice';

  if (user.recipes.length <= 9) {
    return 'apprentice';
  }
  if (user.recipes.length <= 20) {
    return 'commis';
  }
  if (user.recipes.length <= 50) {
    return 'sous-chef';
  }
  return 'chef';
};

