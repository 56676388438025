import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import { FamilyPreview, FamilyType } from '../../../types/Family.type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import Fire from '../../../services/fire.service';
import { updateUser } from '../../../redux/slices/user/userSlice';

const getFirstLetters = (title) => {
  let firstLetters: string =
    (Array.from(title.split(' ')[0] || '')[0] as string) || '';
  const hasMultipleWords = title.split(' ').length > 1;
  if (hasMultipleWords) {
    firstLetters += Array.from(title.split(' ')[1] || '')[0];
  }
  return firstLetters;
};

const sliceName = (title: string) => {
  // if (Array.from(title).length > 10) {
  //   return title.slice(0, 10) + '...';
  // } else
  return title;
};

interface InvitationCardProps {
  family?: FamilyPreview;
  invitationId?: string;
}

const InvitationCard: React.FC<InvitationCardProps> = ({
  family,
  invitationId,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userReducer.user);

  const handleAcceptInvitation = async () => {
    // Add user to family, add family to user, and remove invitation
    const familyFire: FamilyType = await Fire.get('families', family.id);

    const userUpdated = {
      families: [...user.families, family.id],
      invitations: user.invitations.filter(
        (invitation) => invitation !== invitationId
      ),
    };

    if (familyFire.members.some((member) => member.id === user.id)) {
      userUpdated.families = [...user.families];
    } else {
      Fire.update('families', family.id, {
        members: [
          ...familyFire.members,
          {
            id: user.id,
            username: user.username,
            email: user.email,
            grade: user.grade,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        ],
        pendingMembers: familyFire.pendingMembers.filter(
          (pendingMember) => pendingMember !== user.email
        ),
      });
    }

    await Fire.update('users', user.id, userUpdated);
    await dispatch(updateUser(userUpdated));

    await Fire.delete('invitations', invitationId);
  };

  const handleDeclineInvitation = async () => {
    // Remove invitation
    const userUpdated = {
      invitations: user.invitations.filter(
        (invitation) => invitation !== invitationId
      ),
    };

    await Fire.update('users', user.id, userUpdated);
    await dispatch(updateUser(userUpdated));

    await Fire.delete('invitations', invitationId);

    // delete pending member from family
    const familyFire: FamilyType = await Fire.get('families', family.id);

    await Fire.update('families', family.id, {
      pendingMembers: familyFire.pendingMembers.filter(
        (pendingMember) => pendingMember !== user.email
      ),
    });
  };

  return (
    <Box
      sx={{
        padding: '1.5rem 2rem 1.5rem 1.5rem',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.07)',
        transition: 'all .2s ease-in-out',

        '&:hover': {
          boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 70,
            height: 70,
            background: '#F4F7F8',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: 30,
              fontFamily: 'Poetsen One',
              color: 'primary.main',
              textTransform: 'uppercase',
            }}
          >
            {getFirstLetters(family?.title)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            flexDirection: 'column',
            maxWidth: 'calc(100% - 70px)',
            paddingLeft: '2rem',
            width: '100%',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: '1.5rem', sm: '1.25rem' },
              fontWeight: 500,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            Famille {sliceName(family?.title)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '1.15rem', sm: '.9rem' },
                fontWeight: 500,
                marginBottom: '.5rem',
                marginRight: '1rem',
              }}
            >
              {family?.recipesLength || '0'} recette
              {family?.recipesLength > 1 ? 's' : ''}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '1.15rem', sm: '.9rem' },
                fontWeight: 500,
                marginBottom: '.5rem',
              }}
            >
              {family?.membersLength || '0'} membre
              {family?.membersLength > 1 ? 's' : ''}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              marginTop: '.5rem',
              gap: '.5rem',
              width: '100%',
            }}
          >
            <ButtonFilled
              title="Accepter"
              fullWidth
              smallRadius
              smallPadding
              green
              onClick={() => handleAcceptInvitation()}
            />
            <ButtonFilled
              title="Refuser"
              fullWidth
              smallRadius
              smallPadding
              red
              onClick={() => handleDeclineInvitation()}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvitationCard;

