import { Grid } from '@mui/material';
import React from 'react';
import Title from '../../Templates/Title/Title';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import { LoginContext } from '../../../contexts/LoginContext';

const Banner = () => {
  const { openLogin } = React.useContext(LoginContext);

  return (
    <Grid
      item
      container
      xs={12}
      sx={{
        backgroundColor: 'background.default',
        padding: { xs: '3rem 2rem', md: '4.75rem 2rem' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '8rem',
      }}
    >
      <Title
        title="Commencez dès maintenant !"
        small
        black
        sx={{
          marginBottom: '2rem',
        }}
      />
      <ButtonFilled
        title="Rejoindre l’aventure"
        onClick={() => openLogin('sign-up')}
      />
    </Grid>
  );
};

export default Banner;

