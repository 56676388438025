import React, { createContext } from 'react';
import CustomAlert from '../components/Alert/CustomAlert';
import { option } from '../types/Login.type';

export const LoginContext = createContext({
  isLoginOpen: false,
  loginType: 'login',
  openLogin: (type: option) => {
    console.log('open login', type);
  },
  closeLogin: () => {
    console.log('close logon');
  },
  ChangeLoginType: (type: option) => {
    console.log('change login type', type);
  },
});

const LoginContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLoginOpen, setIsLoginOpen] = React.useState<boolean>(false);
  const [loginType, setLoginType] = React.useState<option>('login');

  const openLogin = (type: option) => {
    setIsLoginOpen(true);
    setLoginType(type);
  };

  const closeLogin = () => {
    setIsLoginOpen(false);
  };

  const ChangeLoginType = (type: option) => {
    setLoginType(type);
  };

  return (
    <LoginContext.Provider
      value={{
        isLoginOpen,
        loginType,
        openLogin,
        closeLogin,
        ChangeLoginType,
      }}
    >
      {children}
      <CustomAlert />
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;

