// GENERAL
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { Fire, Tools } from '../../services';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { NewItemsProps } from '../../types/NewItemsProps.type';

// COMPONENTS
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';
import FormLabel from '@mui/material/FormLabel';
import Navbar from '../Templates/Navbar/Navbar';
import FormControl from '@mui/material/FormControl';
import FiltersInput from './FiltersInput';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';

// Import images
import fond_pizza from '../../images/Misc/fond_pizza.jpg';
import IconButton from '@mui/material/IconButton';

//Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    measure: {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '45%',
      },
    },
    ingredientName: {
      width: '30%',
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
    },
    btnDelete: {
      width: '10%',
      [theme.breakpoints.down('sm')]: {
        width: '15%',
      },
    },
    outbox: {
      padding: '5vw 10vw',
      background: `url(${fond_pizza})`,

      [theme.breakpoints.down('sm')]: {
        padding: '5vw',
      },
    },
    input: {
      display: 'none',
    },
  })
);

const AddRecipe: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const randomID = function () {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const [title, setTitle] = useState<string>('');
  const [titleHelper, setTitleHelper] = useState<string>('');
  const [portions, setPortions] = useState<any>([]);
  const [portionsHelper, setPortionsHelper] = useState<string>('');
  const [filters, setFilters] = useState<string[]>([]);
  const [cookingTime, setCookingTime] = useState<number>();
  const [cookingTimeHelper, setCookingTimeHelper] = useState<string>('');
  const [preparationTime, setPreparationTime] = useState<number>();
  const [preparationTimeHelper, setPreparationTimeHelper] =
    useState<string>('');
  const [ingredients, setIngredients] = useState<any>([
    { quantity: '', unit: '', name: '', id: randomID() },
  ]);
  const [picture, setPicture] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pictureExist, setPictureExist] = useState<boolean>(false);
  const [pictureHasChanged, setPictureHasChanged] = useState<boolean>(false);
  const [recipe, setRecipe] = useState<string[]>([]);
  const [introduction, setIntroduction] = useState('');
  const [alertText, setAlertText] = useState<string>('');
  const [alertType, setAlertType] = useState<any>('info');

  const recipesCollection = Fire.store().collection('recipes');
  const [loading, setLoading] = useState<boolean>(false);

  React.useEffect(() => {
    const getRecipe = async () => {
      const recipeRef = await recipesCollection.doc(id);
      const recipe = await Fire.doc(recipeRef);
      recipe.ingredients.sort();

      setTitle(recipe.title);
      setPortions(recipe.portions);
      setFilters(recipe.filters);
      setCookingTime(recipe.cookingTime);
      setPreparationTime(recipe.preparationTime);
      setIngredients(recipe.ingredients);
      setPicture(recipe.image);
      setRecipe(recipe.recipe);
      setIntroduction(recipe.introduction);
    };

    if (id !== 'new') {
      getRecipe();
    } else {
      setTitle('');
      setPortions('');
      setFilters([]);
      setCookingTime(0);
      setPreparationTime(0);
      setIngredients([{ quantity: '', unit: '', name: '', id: randomID() }]);
      setPicture('');
      setRecipe([]);
      setIntroduction('');
    }
  }, [id]);

  const fileChanged = async (evt: any) => {
    try {
      const url = await Tools.requestSmallImage(evt);
      setPicture(url);
    } catch (err) {
      const error = err as { message: string };
      alert(error.message);
    }
  };

  const makeAlert = (text: string, severity = 'info') => {
    setAlertText((prevText) => {
      return prevText === '' ? text : prevText;
    });
    setAlertType(severity);
    setTimeout(() => {
      setAlertText('');
      setAlertType('info');
    }, 2000);
  };

  const addIngredient = () => {
    setIngredients((prev: any[]) => {
      const newIngredients = [
        ...prev,
        {
          quantity: '',
          unit: '',
          name: '',
          id: randomID(),
        },
      ];
      return newIngredients;
    });
  };
  const removeIngredient = (index: number) => {
    setIngredients((prev: any[]) => {
      const newIngredients = prev;
      if (index === 0) {
        return [...newIngredients.splice(1)];
      } else {
        return [
          ...newIngredients.splice(0, index),
          ...newIngredients.splice(index),
        ];
      }
    });
  };

  const onPressEnterKey = (e: any) => {
    if (e.keyCode === 13) {
      saveInfos();
    }
  };

  const saveInfos = async () => {
    try {
      setLoading(true);
      let fieldsFilled = true;
      const newId: string = id === 'new' || !id ? randomID() : id;
      const newDate = new Date();
      const date = newDate.getTime();
      const dd = String(newDate.getDate()).padStart(2, '0');
      const mm = String(newDate.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = newDate.getFullYear();
      const hh = newDate.getHours();
      const min =
        newDate.getMinutes() < 10
          ? newDate.getMinutes() + '0'
          : newDate.getMinutes();
      const today = dd + '/' + mm + '/' + yyyy + ' ' + hh + ':' + min;

      let image = '';

      if (picture !== '') {
        if (pictureHasChanged) {
          const link =
            'recipes/' + title.replace(/\s+/g, '-') + '_' + date + '.png';
          image = await (await Fire.uploadFile(link, picture)).url;
          setPicture(link);
          setPictureExist(true);
          setPictureHasChanged(false);
        } else {
          image = picture;
        }
      } else {
        // makeAlert("Merci de rentrer une belle image !", "error");
        // fieldsFilled = false;
      }

      if (title === '' || title === ' ') {
        makeAlert(
          'Merci de renseigner un titre pour votre excellente recette !',
          'error'
        );
        setTitleHelper('Veuillez entrer un titre');
        fieldsFilled = false;
      } else {
        setTitleHelper('');
      }

      if (portions.length === 0) {
        makeAlert(
          'Merci de renseigner la portion pour votre excellente recette !',
          'error'
        );
        setPortionsHelper('Veuillez entrer la portion');
        fieldsFilled = false;
      } else {
        setPortionsHelper('');
      }

      if (!preparationTime && preparationTime !== 0) {
        makeAlert(
          'Merci de renseigner le temps de préparation pour votre excellente recette !',
          'error'
        );
        setPreparationTimeHelper('Veuillez entrer le temps de preparation');
        fieldsFilled = false;
      } else {
        setPreparationTimeHelper('');
      }

      if (!cookingTime && cookingTime !== 0) {
        makeAlert(
          'Merci de renseigner le temps de cuisson pour votre excellente recette !',
          'error'
        );
        setCookingTimeHelper(
          "Veuillez entrer le temps de cuisson, s'il n'y a pas de temps de cuisson, rentrez 0"
        );
        fieldsFilled = false;
      } else {
        setCookingTimeHelper('');
      }

      if (ingredients.length < 1) {
        makeAlert('Merci de renseigner au moins un ingrédient', 'error');
        fieldsFilled = false;
      }

      if (!fieldsFilled) {
        makeAlert('Merci de remplir tous les champs', 'error');
      } else {
        const item: NewItemsProps = {
          title: title,
          introduction: introduction,
          filters: filters,
          cookingTime: cookingTime,
          preparationTime: preparationTime,
          portions: portions,
          ingredients: ingredients,
          recipe: recipe,
          image: image,
          dateString: today,
          date: new Date(),
          id: newId,
        };
        await recipesCollection.doc(newId).set(item);
        makeAlert(
          'Mmmh, votre recette a bien été ajoutée, merci pour votre contribution !',
          'success'
        );
        navigate('/recipes/' + newId);
      }
    } catch (err) {
      makeAlert(
        "Aïe... Il y a eu une erreur lors de l'envoi du formulaire, rechargez la page et réessayez.",
        'error'
      );
    }
    setLoading(false);
  };

  type newIngredientsProps = {
    quantity: number;
    unit: string;
    name: string;
    id: string;
    index: number;
  };

  function NewIngredient(props: newIngredientsProps) {
    const { quantity, unit, name, id, index } = props;
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        id={id}
        key={id}
        marginBottom="1rem"
      >
        <FormControl className={classes.measure}>
          <TextField
            placeholder="75"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={quantity}
            label="Quantité"
            type="number"
            onBlur={(e) => {
              setIngredients((prev: any[]) => {
                const newIngredients = prev;
                if (e.target.value !== '')
                  newIngredients[index].quantity = e.target.value;
                return newIngredients;
              });
            }}
          />
        </FormControl>
        <FormControl className={classes.measure}>
          <TextField
            placeholder="cl"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={unit}
            label="Unité"
            onBlur={(e) => {
              setIngredients((prev: any[]) => {
                const newIngredients = prev;
                newIngredients[index].unit = e.target.value;
                return newIngredients;
              });
            }}
          />
        </FormControl>
        <FormControl className={classes.ingredientName}>
          <TextField
            placeholder="de lait"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            label="Ingrédient"
            defaultValue={name}
            onBlur={(e) => {
              setIngredients((prev: any[]) => {
                const newIngredients = prev;
                newIngredients[index].name = e.target.value;
                return newIngredients;
              });
            }}
          />
        </FormControl>
        <IconButton
          aria-label="delete"
          size="small"
          disabled={ingredients.length === 1}
          sx={{
            opacity: ingredients.length === 1 ? '0' : '1',
            transition: 'all .3s ease-in-out',
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              removeIngredient(index);
            }
          }}
          onClick={() => removeIngredient(index)}
        >
          {<DeleteOutlineIcon fontSize="large" />}
        </IconButton>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Navbar />
      <Fade in timeout={500}>
        <Box className={classes.outbox}>
          <Box
            padding={mobile ? '2rem' : '2rem 3rem'}
            bgcolor="rgba(255, 255, 255, .95)"
            maxWidth="1250px"
            margin="auto"
            borderRadius="2rem"
          >
            <form action="">
              <div className="input-wrapper">
                <FormLabel
                  component="legend"
                  style={{ margin: '1rem 0 .5rem' }}
                >
                  Image de la recette :
                </FormLabel>
                <div className="flex-row">
                  <div className="input-preview">
                    {picture && picture !== '' && (
                      <img
                        alt="Image"
                        src={picture}
                        style={{
                          maxWidth: 300,
                          width: '100%',
                          marginBottom: '1rem',
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <input
                      accept="image/png, image/jpeg"
                      className={classes.input}
                      id="contained-button-file"
                      type="file"
                      onChange={(evt) => {
                        setPictureHasChanged(true);
                        fileChanged(evt);
                      }}
                      disabled={loading}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        sx={{
                          color: 'white',
                          borderRadius: '100px',
                          padding: '.3rem 2rem',
                          textTransform: 'none',
                          fontSize: '.9rem',
                          boxShadow: '0px 25px 31px rgba(101, 188, 48, 0.24)',
                          margin: 'inherit auto',
                          width: 'fit-content',

                          '&:hover': {
                            boxShadow: '0px 10px 21px rgba(101, 188, 48, 0.3)',
                            background: '#549D27',
                          },
                        }}
                      >
                        {picture ? "Changer l'image" : 'Ajouter'}
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Titre*
              </FormLabel>
              <TextField
                placeholder="ex : Tarte aux fraises"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                helperText={titleHelper}
                error={!(titleHelper === '' || titleHelper === ' ')}
              />
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Introduction
              </FormLabel>
              <TextField
                placeholder="ex : Acheter des escalopes de veau de qualité, et les demander très fines au boucher."
                fullWidth
                margin="normal"
                value={introduction}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onChange={(e) => setIntroduction(e.target.value)}
              />
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Portions*
              </FormLabel>
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <TextField
                  placeholder="ex : 6"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={portionsHelper}
                  variant="outlined"
                  value={portions.quantity || ''}
                  onChange={(e) => {
                    setPortions((prev: any) => ({
                      quantity: e.target.value,
                      measure: prev.measure,
                    }));
                  }}
                  error={!(portionsHelper === '' || portionsHelper === ' ')}
                  sx={{
                    width: '15%',
                  }}
                />
                <TextField
                  placeholder="ex : verrines"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={portionsHelper}
                  variant="outlined"
                  value={portions.measure || ''}
                  onChange={(e) => {
                    setPortions((prev: any) => ({
                      quantity: prev.quantity,
                      measure: e.target.value,
                    }));
                  }}
                  error={!(portionsHelper === '' || portionsHelper === ' ')}
                  sx={{
                    width: '80%',
                    marginLeft: 'auto',
                  }}
                />
              </FormControl>
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Catégorie* (Vous pouvez en ajouter plusieurs !)
              </FormLabel>
              <FiltersInput filters={filters} setFilters={setFilters} />
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Ingrédients*
              </FormLabel>
              {ingredients.map((ingredient: any, index: number) => {
                return (
                  <NewIngredient
                    key={ingredient.id}
                    {...ingredient}
                    index={index}
                  />
                );
              })}
              <IconButton
                aria-label="delete"
                size="small"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    addIngredient();
                  }
                }}
                onClick={addIngredient}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Temps de préparation* (minutes)
              </FormLabel>
              <TextField
                placeholder="ex : 35"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={preparationTimeHelper}
                error={
                  !(
                    preparationTimeHelper === '' ||
                    preparationTimeHelper === ' '
                  )
                }
                type="number"
                onChange={(e) => {
                  setPreparationTime(parseInt(e.target.value.split(' ', 2)[0]));
                }}
                value={preparationTime || ''}
              />
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Temps de cuisson* (minutes)
              </FormLabel>
              <TextField
                placeholder="ex : 10"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={cookingTimeHelper}
                error={!(cookingTimeHelper === '' || cookingTimeHelper === ' ')}
                type="number"
                onChange={(e) => {
                  setCookingTime(parseInt(e.target.value.split(' ', 2)[0]));
                }}
                value={cookingTime || ''}
              />
              <FormLabel component="legend" style={{ margin: '1rem 0 0' }}>
                Recette* (sautez une ligne entre chaque étape)
              </FormLabel>
              <TextField
                placeholder="1 - La pâte &#13;&#10;Mélanger la farine et les oeufs pour former une pâte homogène. &#13;&#10;2 - La cuisson &#13;&#10;Faire cuire pendant 5 minutes.&#13;&#10;3 - Dernière étape &#13;&#10;Régalez-vous !"
                multiline
                fullWidth
                rows={8}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                onBlur={(e) => {
                  const recipesArray = e.target.value.split('\n');
                  const filteredArray = recipesArray.filter(
                    (el) => el != null && el != ''
                  );
                  setRecipe(filteredArray);
                }}
                defaultValue={recipe.join('\n') || ''}
              />
              <ButtonFilled
                onClick={saveInfos}
                margin="1rem 0 0"
                onKeyDown={onPressEnterKey}
                title="Envoyer"
              />
            </form>
            <Box position="fixed" bottom="10%" left="5%">
              {alertText !== '' && alertText !== ' ' && (
                <Alert severity={alertType}>{alertText}</Alert>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </React.Fragment>
  );
};

export default AddRecipe;

