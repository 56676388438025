import React from 'react';

// IMPORT COMPONENTS
import { Box } from '@mui/material';

interface CardGeneralProps {
  children: React.ReactNode;
  noBg?: boolean;
  noPadding?: boolean;
  noRadius?: boolean;
  padding?: string;
}

const CardGeneral: React.FC<CardGeneralProps> = ({
  children,
  noBg,
  noPadding,
  noRadius,
  padding,
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: noRadius ? 0 : '10px',
        padding: noPadding ? '0' : padding || '1.5rem',
        backgroundColor: noBg ? 'transparent' : 'background.paper',
      }}
    >
      {children}
    </Box>
  );
};

export default CardGeneral;

