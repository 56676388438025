/* eslint-disable indent */
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  MenuItem,
  Menu,
  Modal,
  Theme,
  Typography,
  Link,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logo from '../../../images/Misc/Logo.svg';
import clsx from 'clsx';
import Login from './Login';
import { Fire } from '../../../services';

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/slices/user/userSlice';
import { RootState } from '../../../redux/store/store';
import { useLocation } from 'react-router';
import { LoginContext } from '../../../contexts/LoginContext';
import { openModal } from '../../../redux/slices/recipe/recipeSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outsideContainer: {
      padding: '0 !important',
      zIndex: 1,
      '& .MuiToolbar-root': {
        padding: '0 !important',
      },
    },
    navbarDisplayFlex: {
      display: `flex !important`,
      justifyContent: `space-between`,
      alignItems: `center`,
      padding: `1rem 3.5rem !important`,
      [theme.breakpoints.down('sm')]: {
        padding: `1rem 2rem !important`,
      },
    },
    appBar: {
      zIndex: 1,
      boxShadow: '-1px 25px 15px 0px rgba(0, 0, 0, 0.02) !important',
      position: 'fixed',
      '&>div': {
        maxWidth: '1350px',
        margin: 'auto',
        width: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        boxShadow: '0px 25px 15px 0px rgba(0, 0, 0, 0.04) !important',
      },
    },
    transparent: {
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    },
    logo: {
      maxWidth: '45px',
      marginRight: '1.5rem',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        maxWidth: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '35px',
      },
    },
    search: {
      position: 'relative',
      borderRadius: '2rem',
      height: 'max-content',
      boxShadow: '2px 4px 27px 1px rgba(17, 17, 17, 0.08)',
      flexGrow: 1,
      maxWidth: 'min(40vw, 600px)',
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      backgroundColor: 'white',

      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
      },
    },
    searchIcon: {
      top: '50%',
      transform: 'translateY(-50%)',
      right: '.25rem',
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      zIndex: 1,
      '&>svg': {
        fontSize: '1.75rem !important',
        fill: 'rgba(17, 17, 17, 0.5)',
      },
      '&:hover': {
        '&>svg': {
          fill: 'rgba(17, 17, 17, 0.75)',
        },
      },
    },
    iconButton: {
      padding: '0 !important',
      margin: '0 .5rem !important',
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.dark,
        boxShadow: '0 0 33px 0 rgb(255 255 255 / 11%)',
      },

      '& svg': {
        fontSize: 35,
        [theme.breakpoints.up('sm')]: {
          fontSize: 40,
        },
        [theme.breakpoints.up('md')]: {
          fontSize: 45,
        },
      },
    },
    inputRoot: {
      color: 'grey',
      width: '100%',
    },
    inputInput: {
      transition: theme.transitions.create('width'),
      width: '100%',
      color: 'rgba(17, 17, 17, .9) !important',
      fontWeight: 300,
      '&::placeholder': {
        color: 'rgba(17, 17, 17, .75) !important',
      },
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
      [theme.breakpoints.down('sm')]: {
        width: '20ch',
        fontSize: '1.15rem !important',
      },
    },
    popoverMenu: {
      '&>div.MuiPaper-root': {
        padding: '.5rem',

        '&>ul': {
          padding: '0',
          '&>li': {
            fontWeight: 600,
            color: 'text.primary',
            fontSize: '.9rem',
            paddingRight: '2rem',
            '&:hover': {
              backgroundColor: '#ecf0f8',
            },
          },
        },
      },
    },
  })
);

interface Props {
  transparent?: boolean;
}

const Navbar = (props: Props): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { transparent } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const dispatch = useDispatch();

  // get url parameters
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isSignup = searchParams.get('signup');
  const isLogin = searchParams.get('login');
  const openRecipe = searchParams.get('openRecipe');
  const { isLoginOpen, loginType, openLogin, closeLogin, ChangeLoginType } =
    React.useContext(LoginContext);

  // get user from redux

  const user = useSelector((state: RootState) => state.userReducer.user);
  //Drawer Account
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Fire.auth().signOut();
    dispatch(logout());
  };

  React.useEffect(() => {
    if (!user && window.location.pathname === '/') {
      if (isSignup) {
        openLogin('sign-up');
      } else if (isLogin) {
        openLogin('login');
      }
    }
    if (user && window.location.pathname === '/') {
      if (openRecipe) {
        navigate(`/`);
        dispatch(openModal());
      }
    }
  }, [isSignup, isLogin]);

  return (
    <Container className={classes.outsideContainer} maxWidth={false}>
      <Box
        position="relative"
        sx={{
          minHeight: { xs: '74px', md: '80px' },
        }}
      >
        <AppBar
          position="static"
          className={clsx(classes.appBar, transparent && classes.transparent)}
        >
          <Toolbar style={{ zIndex: 1 }}>
            <Container className={classes.navbarDisplayFlex}>
              <Link
                onClick={() => navigate('/')}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <Box display="flex" alignItems="center">
                  <img src={Logo} className={classes.logo} />
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 600,
                      display: {
                        xs: 'none',
                        sm: 'block',
                      },
                      fontSize: {
                        md: '1.5rem',
                        sm: '1.2rem',
                      },
                      cursor: 'pointer',
                      fontFamily: 'Poetsen One',
                      marginRight: '1rem',
                    }}
                  >
                    The Kitchen Book
                  </Typography>
                </Box>
              </Link>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div className={classes.search}>
                  <InputBase
                    placeholder="Je cherche..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    sx={{
                      padding: {
                        xs: '.5rem 4rem .5rem 1.5rem !important',
                        sm: '.5rem 4rem .5rem 1.5rem !important',
                        md: '.6rem 4rem .6rem 1.5rem !important',
                      },
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.charCode == 13) {
                        navigate({
                          pathname: '/recipes',
                          search: `?q=${
                            (e.target as HTMLTextAreaElement).value
                          }`,
                        });
                      }
                    }}
                  />
                  <div
                    className={classes.searchIcon}
                    onClick={() => {
                      navigate({
                        pathname: '/recipes',
                        search: `?q=${searchValue}`,
                      });
                    }}
                  >
                    <SearchIcon />
                  </div>
                </div>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    className={classes.iconButton}
                    onClick={handleMenu}
                    color="primary"
                  >
                    {user ? (
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          background: '#F4F7F8',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: 25,
                            fontFamily: 'Poetsen One',
                            color: 'text.primary',
                          }}
                        >
                          {Array.from(user?.firstName || '')[0]}
                          {Array.from(user?.lastName || '')[0]}
                        </Typography>
                      </Box>
                    ) : (
                      <AccountCircle />
                    )}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                    className={classes.popoverMenu}
                  >
                    <MenuItem
                      onClick={() => {
                        user
                          ? (handleClose(), navigate('/account'))
                          : (handleClose(), openLogin('login'));
                      }}
                      sx={{
                        minHeight: '0px',
                        padding: '.55rem 1.5rem',
                        fontSize: {
                          xs: '1rem !important',
                          sm: '.9rem !important',
                        },
                      }}
                    >
                      {user ? 'Mon compte' : 'Se connecter'}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        user
                          ? (handleLogout(), handleClose())
                          : (handleClose(), openLogin('sign-up'));
                      }}
                      sx={{
                        minHeight: '0px',
                        padding: '.55rem 1.5rem',
                        fontSize: {
                          xs: '1rem !important',
                          sm: '.9rem !important',
                        },
                      }}
                    >
                      {user ? 'Déconnexion' : "S'inscrire"}
                    </MenuItem>
                  </Menu>
                  <Modal
                    open={isLoginOpen}
                    onClose={() => closeLogin()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    keepMounted
                  >
                    <Box
                      width="100%"
                      height="100vh"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ pointerEvents: 'none' }}
                    >
                      <Login
                        setOption={ChangeLoginType}
                        option={loginType}
                        handleClose={() => closeLogin()}
                      />
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
      </Box>
    </Container>
  );
};

export default Navbar;

