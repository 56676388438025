import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import WomanCooking2 from '../../../images/Accueil/Woman_cooking_2.jpg';
import GrandmaGirlCooking from '../../../images/Accueil/Grandma_girl_cooking.jpg';
import WomanCooking from '../../../images/Accueil/Woman_cooking.jpg';
import PizzaCooking from '../../../images/Accueil/Pizza_cooking_2.jpg';
import FamilyCooking from '../../../images/Accueil/Family_cooking.jpg';

const cardSx = {
  width: '100%',
  borderRadius: '13px',
  marginBottom: '2rem',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: { xs: '2rem', md: '3.25rem' },
  display: 'flex',
  justifyContent: 'center',
};

interface TitleProps {
  title: string;
  align?: 'center' | 'left';
}

const Title: React.FC<TitleProps> = ({ title, align = 'center' }) => {
  return (
    <Typography
      variant="h3"
      sx={{
        color: 'white',
        marginBottom: '1rem',
        textAlign: align,
        fontFamily: 'Poppins',
        fontSize: {
          xs: '2rem',
          sm: '2rem',
          md: '2.15rem',
        },
        fontWeight: 500,
        lineHeight: '120%',
        maxWidth: '23ch',
      }}
    >
      {title}
    </Typography>
  );
};

const HomeCards = () => {
  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: { xs: '4rem 2rem', md: '4rem 3.5rem' },
        width: '100%',
        display: 'flex',
        maxWidth: '1200px !important',
        margin: 'auto',
        background: 'white',
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={8}
        sx={{
          paddingRight: { sm: 0, md: '1rem' },
        }}
      >
        <Box
          sx={{
            height: '22rem',
            background: `linear-gradient(180deg, rgba(19, 19, 19, 0.65) 0%, rgba(2, 2, 2, 0.39) 73.96%, rgba(19, 19, 19, 0.00) 100%), url(${WomanCooking2})`,
            transition: 'all 0.3s ease-in-out',
            alignItems: {
              xs: 'center',
              md: 'flex-start',
            },
            ...cardSx,
          }}
        >
          <Title title="Regroupez vos recettes familiales en un seul endroit facile d'accès." />
        </Box>
        <Box
          sx={{
            height: '22rem',
            background: `linear-gradient(0deg, rgba(19, 19, 19, 0.75) 0%, rgba(2, 2, 2, 0.49) 23.96%, rgba(19, 19, 19, 0.00) 100%), url(${GrandmaGirlCooking})`,
            alignItems: 'flex-end',
            ...cardSx,
          }}
        >
          <Title title="Partagez vos plats favoris avec vos proches." />
        </Box>
        <Box
          sx={{
            height: { xs: '22rem', md: '16rem' },
            background: `linear-gradient(180deg, rgba(19, 19, 19, 0.82) 0%, rgba(19, 19, 19, 0.00) 112.32%), url(${WomanCooking})`,
            alignItems: {
              xs: 'flex-start',
              md: 'center',
            },
            ...cardSx,
          }}
        >
          <Title
            title="Trouvez aisément les recettes dont vous avez besoin."
            align="left"
          />
        </Box>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        sx={{
          paddingLeft: { sm: 0, md: '1rem' },
        }}
      >
        <Box
          sx={{
            height: { xs: '22rem', md: '38rem' },
            background: `linear-gradient(0deg, rgba(19, 19, 19, 0.75) 0%, rgba(2, 2, 2, 0.49) 23.96%, rgba(19, 19, 19, 0.00) 100%), url(${PizzaCooking})`,
            alignItems: 'flex-end',
            ...cardSx,
            backgroundPosition: '65%',
          }}
        >
          <Title
            title="Ajoutez votre touche personnelle aux recettes partagées."
            align="left"
          />
        </Box>
        <Box
          sx={{
            height: { xs: '22rem', md: '24rem' },
            background: `linear-gradient(0deg, rgba(19, 19, 19, 0.75) 0%, rgba(2, 2, 2, 0.49) 23.96%, rgba(19, 19, 19, 0.00) 100%), url(${FamilyCooking})`,
            alignItems: 'flex-end',
            ...cardSx,
          }}
        >
          <Title title="Créez des souvenirs inoubliables en cuisinant ensemble." />
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomeCards;

