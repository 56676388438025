import { Box, Modal, TextField, Typography } from '@mui/material';
import React from 'react';
import Title from '../../Templates/Title/Title';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { FamilyPayload } from '../../../types/Family.type';

interface CreateFamilyModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (payload: FamilyPayload) => void;
}

interface Member {
  email: string;
  id: string;
}

const CreateFamilyModal: React.FC<CreateFamilyModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [members, setMembers] = React.useState<Member[]>([
    {
      email: '',
      id: 'initialMember',
    },
  ]);
  const [familyName, setFamilyName] = React.useState<string>('');
  const [familyError, setFamilyError] = React.useState<string>('');
  const [memberError, setMemberError] = React.useState<string>('');
  // useRef on div to scroll to bottom
  const membersRef = React.useRef<HTMLDivElement>(null);

  const onAddMember = () => {
    const newId = Date.now();
    setMembers([
      ...members,
      {
        email: '',
        id: newId.toString(),
      },
    ]);
    setTimeout(() => {
      membersRef.current?.scrollTo(0, membersRef.current?.scrollHeight);
    }, 100);
  };

  const onDeleteMember = (index: number) => {
    const newMembers = [...members];
    newMembers.splice(index, 1);
    setMembers(newMembers);
  };

  const onChangeMember = (index: number, value: string) => {
    const newMembers = [...members];
    newMembers[index] = {
      ...newMembers[index],
      email: value,
    };
    setMembers(newMembers);

    if (index === members.length - 1 && value !== '') {
      onAddMember();
    }
  };

  const validate = () => {
    let isValid = true;

    if (!familyName) {
      setFamilyError('Veuillez renseigner un nom de famille');
      isValid = false;
    } else {
      setFamilyError('');
    }

    if (members.length === 1 && members[0].email === '') {
      setMemberError('Veuillez renseigner au moins un membre');
      isValid = false;
    } else {
      setMemberError('');
    }

    if (isValid) {
      const newMembers: string[] = members
        .map((member) => member.email)
        .filter((member) => member !== '');

      onSubmit({
        title: familyName,
        members: newMembers,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Créer une famille"
      aria-describedby="Ce modal sert à créer une famille"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: '2rem',
          background: 'white',
          borderRadius: 3,
          width: '90%',
          maxWidth: 700,
          height: '90vh',
          maxHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '6%',
            right: '2rem',
            cursor: 'pointer',
          }}
        >
          <CloseRoundedIcon onClick={onClose} />
        </Box>
        <Box
          sx={{
            height: '10%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Title title="Créer une famille" small />
        </Box>
        <Box
          sx={{
            marginTop: '1rem',
            height: 'calc(77% - 2rem)',
            overflow: 'auto',
            scrollBehavior: 'smooth',
            paddingRight: members.length > 1 ? '1rem' : 0,
            '&::-webkit-scrollbar': {
              width: '6px',
              backgroundColor: '#F5F5F5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              backgroundColor: '#aaa',
            },
          }}
          ref={membersRef}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 500,
              marginBottom: '1.5rem',
            }}
          >
            Nom de votre famille :
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Exemple : Dupont"
            fullWidth
            onChange={(e) => setFamilyName(e.target.value)}
            helperText={familyError}
            sx={{
              marginBottom: '2rem',
              input: {
                padding: '.75rem 1rem',
              },
              // helper text
              '& .MuiFormHelperText-root': {
                color: 'error.main',
              },
            }}
          />
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 500,
              marginBottom: '0.5rem',
            }}
          >
            Emails des membres de votre famille :
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: '.9rem',
              color: '#9B9B9B',
              fontWeight: 500,
              marginBottom: '1.5rem',
            }}
          >
            (Vous pourrez en rajouter plus tard !)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              width: '100%',
              paddingTop: '2px',
            }}
          >
            {members.map((member, index) => (
              <Box
                key={member.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: '1rem',
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Exemple : etchebest@gmail.com"
                  fullWidth
                  onChange={(e) => onChangeMember(index, e.target.value)}
                  helperText={memberError}
                  type="email"
                  sx={{
                    input: {
                      padding: '.75rem 1rem',
                    },
                    // helper text
                    '& .MuiFormHelperText-root': {
                      color: 'error.main',
                    },
                  }}
                />
                {members.length > 1 && (
                  <Box
                    sx={{
                      width: '50px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <DeleteIcon
                      sx={{
                        cursor: 'pointer',
                        color: 'error.main',
                        fontSize: { xs: '2rem', sm: '1.8rem' },
                        '&:hover': {
                          color: 'error.dark',
                        },
                      }}
                      onClick={() => onDeleteMember(index)}
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 'max(auto, 2rem)',
          }}
        >
          <ButtonFilled onClick={validate} sx={{}} title="Créer ma famille" />
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateFamilyModal;

