// IMPORT GENERAL
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Fade, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

//ALGOLIA
import algoliasearch from 'algoliasearch/lite';

// IMPORT COMPONENTS
import Navbar from '../Templates/Navbar/Navbar';
import { Grid, Box, Typography } from '@mui/material';
import { FilteredRecipes, RecipeProps } from '../../types/RecipeProps.type';
import RecipeCard from '../Templates/RecipeCard/RecipeCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import Title from '../Templates/Title/Title';
import RecipeLoading from './RecipeLoading';

const useStyles = makeStyles(() =>
  createStyles({
    homeScreen: {
      maxWidth: 1300,
      margin: 'auto',
    },
  })
);

const getQueryParams = (param: string) => {
  return unescape(
    decodeURIComponent(window.location.search).replace(
      new RegExp(
        '^(?:.*[&\\?]' +
          escape(param).replace(/[.+*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
        'i'
      ),
      '$1'
    )
  );
};

const getBigContainerProps = (marginTop?: boolean) => {
  return {
    container: true,
    sx: {
      maxWidth: '1250px',
      width: '100%',
      margin: `${marginTop ? '2rem' : '0'} auto 2rem auto`,
      padding: '0 2rem ',
    },
  };
};

const getRecipesContainerProps = (mobile) => {
  return {
    container: true,
    item: true,
    xs: 12,
    spacing: mobile ? 3 : 6,
    sx: {
      width: '100%',
      maxWidth: '1250px',
      justifyContent: 'flex-start',
    },
  };
};

export default function RecipesPage(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [recipesLength, setRecipesLength] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredRecipes, setFilteredRecipes] = useState<FilteredRecipes>({
    publicRecipes: [],
    privateRecipes: [],
  });
  const query = getQueryParams('q');
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useSelector((state: RootState) => state.userReducer);

  const searchClient = algoliasearch(
    'AZX5076CN4',
    '2bb1a6c5feac1bf7757e4837240c320e'
  );

  const index = searchClient.initIndex('recipes');

  const filterRecipes = async (recipes: RecipeProps[]) => {
    const privateRecipes = recipes.filter((recipe) => {
      return (
        recipe.author?.id === user?.id ||
        recipe.families?.some((family) => user?.families?.includes(family))
      );
    });

    // Set Public recipes, get only recipes that are not in user's families, no matter if they are public or private
    const publicRecipes = recipes.filter((recipe) => {
      return !(
        recipe.author?.id === user?.id ||
        recipe.families?.some((family) => user?.families?.includes(family))
      );
    });

    setFilteredRecipes({ publicRecipes, privateRecipes });
  };

  const search = async () => {
    setIsLoading(true);
    try {
      const result: {
        hits: RecipeProps[];
      } = await index.search(query, {
        filters:
          // Recipe is public, is the author recipe or it is one of the user's family
          `isPublic:true OR author.id:${
            user?.id
          } OR (families:${user?.families?.join(' OR families:')})`,
      });
      setRecipesLength(result.hits.length);
      filterRecipes(result.hits);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    search();
  }, [query]);

  return (
    <Fade in timeout={500}>
      <Box width="100%" height="auto" minHeight="100vh">
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid container className={classes.homeScreen}>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            sx={{
              maxWidth: '1250px',
              margin: '0 auto 2rem auto',
              justifyContent: 'flex-start',
              padding: {
                xs: '1rem 2rem 0',
                sm: '2rem 2rem 0',
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">{query}</Typography>
                <Typography
                  variant="h6"
                  component={'span'}
                  style={{ marginTop: '.5rem' }}
                >
                  <Box color={theme.palette.text.secondary}>
                    {recipesLength} recettes
                  </Box>
                </Typography>
              </Box>
              <Box
                style={{ cursor: 'pointer' }}
                display="flex"
                alignItems="center"
              >
                {/* <FilterListIcon />
                <Typography
                  variant="h6"
                  component={"span"}
                  style={{ color: theme.palette.text.secondary }}
                >
                  <Box color={theme.palette.text.secondary} marginLeft=".75rem">
                    Filtrer les résultats
                  </Box>
                </Typography> */}
              </Box>
            </Box>
          </Grid>
          {isLoading && (
            <Grid {...getBigContainerProps(true)}>
              <Grid {...getRecipesContainerProps(mobile)}>
                {[1, 2, 3, 4].map((recipe) => {
                  return <RecipeLoading key={`container-recipe-${recipe}`} />;
                })}
              </Grid>
            </Grid>
          )}

          {filteredRecipes.privateRecipes.length !== 0 && (
            <Grid {...getBigContainerProps()}>
              <Title
                title="Recettes de vos familles"
                sx={{
                  marginBottom: '2rem',
                }}
              />

              <Grid {...getRecipesContainerProps(mobile)}>
                {filteredRecipes.privateRecipes.map((recipe: RecipeProps) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      key={`container-${recipe.id || recipe.objectID}`}
                    >
                      <RecipeCard recipe={recipe} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
          {filteredRecipes.publicRecipes.length !== 0 && (
            <Grid {...getBigContainerProps(true)}>
              <Title
                title="Recettes publiques"
                sx={{
                  marginBottom: '2rem',
                }}
              />

              <Grid {...getRecipesContainerProps(mobile)}>
                {filteredRecipes.publicRecipes.map((recipe: RecipeProps) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={4}
                      md={3}
                      key={`container-${recipe.id || recipe.objectID}`}
                    >
                      <RecipeCard recipe={recipe} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Fade>
  );
}

