import React from 'react';
import { useNavigate } from 'react-router-dom';
import MarmiteIcon from '../../../images/marmitte.jpeg';
import { Box, Skeleton, Typography } from '@mui/material';
import { RecipeProps } from '../../../types/RecipeProps.type';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateRecipe,
  openModal,
} from '../../../redux/slices/recipe/recipeSlice';
import { smallGrades, gradesColor } from '../../../utils/utils';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { updateUser } from '../../../redux/slices/user/userSlice';
import { RootState } from '../../../redux/store/store';
import { Fire } from '../../../services';
import { useAlert } from '../../../hooks/useAlert';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  draft?: boolean;
  recipe?: RecipeProps;
  hideAuthor?: boolean;
  isLoadingCard?: boolean;
}

const RecipeCard: React.FC<Props> = ({
  recipe,
  draft,
  hideAuthor,
  isLoadingCard,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const user = useSelector((state: RootState) => state.userReducer.user);

  const getAddTime: (time1: number, time2: number) => string | JSX.Element = (
    time1: number,
    time2: number
  ) => {
    const time = time1 + time2;
    return getLiteralTime(time);
  };

  const getLiteralTime: (time: number) => string | JSX.Element = (
    time: number
  ) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours !== 0 ? hours + 'h' : ''} ${
      minutes !== 0 ? minutes + 'min' : ''
    }`;
  };

  const deleteDraft = async (e) => {
    e.stopPropagation();
    if (!recipe) return;
    if (!draft) return;

    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce brouillon ?')) {
      const newDrafts = user?.draftRecipes.filter(
        (draft) => draft.id !== recipe?.id
      );
      await dispatch(updateUser({ draftRecipes: newDrafts }));

      await Fire.update('users', user?.id, { draftRecipes: newDrafts }).then(
        () => {
          showAlert('Le brouillon a bien été supprimé', 'success');
        }
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        width: '100%',
        height: '100%',
        cursor: isLoadingCard ? 'default' : 'pointer',
        transition: 'all .2s ease-in-out',
        backgroundColor: 'transparent',
        position: 'relative',

        '& > *': {
          transition: 'all .2s ease-in-out',
        },

        '&:hover > *': {
          opacity: 0.7,
        },
        '&:hover .deleteRecipe': {
          opacity: 1,
        },
      }}
      onClick={() => {
        if (isLoadingCard) return;
        if (draft) {
          dispatch(updateRecipe({ recipe: recipe, isUpdating: true }));
          dispatch(openModal());
        } else
          navigate(
            recipe?.path
              ? `/${recipe?.path}`
              : `/recipes/${recipe?.id || recipe?.objectID}`
          );
      }}
    >
      {draft && (
        <Box
          sx={{
            position: 'absolute',
            top: -10,
            left: -10,
            background: 'rgba(239, 35, 60, 0.14)',
            borderRadius: '50px',
            padding: '0.25rem',
            backdropFilter: 'blur(4px)',
            zIndex: 1,
            opacity: 0,
            transition: 'all .2s ease-in-out',
          }}
          className="deleteRecipe"
          onClick={deleteDraft}
        >
          <DeleteIcon
            sx={{
              color: '#EF233C',
              fontSize: '1.75rem',
              fontWeight: 500,
              lineHeight: '130%',
              textAlign: 'center',
              opacity: '1 !important',
              cursor: 'pointer',
              display: 'block',
            }}
          />
        </Box>
      )}
      {draft && (
        <Box
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            background: 'rgba(31, 32, 38, 0.50)',
            borderRadius: '50px',
            padding: '0.25rem .75rem',
            backdropFilter: 'blur(4px)',
            zIndex: 1,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: 'white',
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: '130%',
              textAlign: 'center',
              opacity: '1 !important',
            }}
          >
            Brouillon
          </Typography>
        </Box>
      )}
      {isLoadingCard ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            borderRadius: '5px',
            aspectRatio: '1/1',
          }}
        />
      ) : (
        <Box
          sx={{
            backgroundImage: `url("${recipe?.image || MarmiteIcon}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            borderRadius: '5px',
            paddingTop: '100%',
            opacity: draft ? 0.5 : 1,
          }}
        />
      )}
      <Typography
        variant="body1"
        sx={{
          opacity: draft ? 0.5 : 1,
          fontSize: { xs: '1.3rem', sm: '1.15rem' },
          marginTop: '1rem ',
          width: '100%',
          fontWeight: 500,
          textAlign: 'left',
          lineHeight: '130%',
          transition: 'opacity .2s ease-in-out',
        }}
      >
        {isLoadingCard ? (
          <Skeleton variant="text" width={'100%'} />
        ) : (
          recipe?.title
        )}
      </Typography>
      <Box
        sx={{
          marginTop: '.5rem ',
          display: 'flex',
          alignItems: 'center',
          '& p': {
            fontSize: { xs: '1rem', sm: '.85rem' },
          },
        }}
      >
        <WatchLaterIcon
          color="primary"
          sx={{
            fontSize: '1.15rem',
            display: isLoadingCard ? 'none' : 'block',
          }}
        />
        <Typography
          variant="body1"
          color="primary"
          style={{
            marginLeft: '.3rem',
            fontWeight: 500,
          }}
        >
          {isLoadingCard ? (
            <Skeleton variant="text" width={100} />
          ) : (
            getAddTime(recipe?.preparationTime || 0, recipe?.cookingTime || 0)
          )}
        </Typography>
      </Box>
      {!hideAuthor && recipe?.author && (
        <Box
          sx={{
            padding: '.25rem .75rem',
            borderRadius: 20,
            background: '#F4F7F8',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '.5rem',
            width: 'fit-content',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: { xs: '.9rem', sm: '.8rem' },
              fontWeight: 500,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WorkspacePremiumIcon
              sx={{
                marginRight: '.5rem',
                fontSize: '1.15rem',
                color: gradesColor[recipe.author?.grade],
              }}
            />
            {smallGrades[recipe.author?.grade]} {recipe.author?.username}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RecipeCard;

