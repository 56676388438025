import React from 'react';
import { Box, Grid, Link, Modal, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Title from '../../Templates/Title/Title';
import CustomOutlinedInput from '../../Templates/Inputs/CustomOutlinedInput';
import { useUnsplash } from '../../../hooks/useUnsplash';
import { useAlert } from '../../../hooks/useAlert';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
interface UnsplashModalProps {
  onClose: () => void;
  open: boolean;
  handleChangeImageUnsplash: (url: string) => void;
  defaultValue?: string;
}

type Image = {
  id: string;
  alt_description: string;
  urls: {
    full: string;
    raw: string;
    regular: string;
    small: string;
    thumb: string;
  };
  user: {
    name: string;
    username: string;
    links: {
      html: string;
    };
  };
};

const UnsplashModal: React.FC<UnsplashModalProps> = ({
  onClose,
  open,
  handleChangeImageUnsplash,
  defaultValue,
}) => {
  const [search, setSearch] = React.useState<string>('');
  const [images, setImages] = React.useState<Image[]>([]);

  const { queryImages, triggerDownload } = useUnsplash();
  const { showAlert } = useAlert();

  React.useEffect(() => {
    if (defaultValue) {
      setSearch(defaultValue);
    }
  }, [defaultValue]);

  React.useEffect(() => {
    if (open && search !== '') {
      handleSearch();
    }
  }, [open]);

  const handleSearch = async () => {
    if (!search || search === '') return;
    if (search.length < 3)
      return showAlert('Veuillez entrer au moins 3 caractères', 'error');
    try {
      const images = await queryImages(search);
      setImages(images);
    } catch (err: any) {
      showAlert('Une erreur est survenue : ' + err.response.data, 'error');
    }
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onChangeImage = (image: any) => {
    handleChangeImageUnsplash(image.urls.regular);
    triggerDownload(image.links.download_location);
    onClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="Choisir une photo par Unsplash"
      aria-describedby="Ce modal sert à choisir une photo par Unsplash"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: { xs: '2rem 1.5rem', sm: '3rem' },
          background: 'white',
          borderRadius: 3,
          width: { xs: '90%', sm: '90%' },
          maxWidth: 700,
          height: { xs: '70vh', sm: '75vh' },
          maxHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '6%',
            right: '2rem',
            cursor: 'pointer',
          }}
        >
          <CloseRoundedIcon onClick={onClose} />
        </Box>
        <Title title={"Librairie d'images"} small />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '2rem',
          }}
        >
          <CustomOutlinedInput
            label={
              <Box>
                Recherchez une image sur{' '}
                <Link
                  href="https://unsplash.com/?utm_source=The_Kitchen_Book&utm_medium=referral"
                  target="_blank"
                >
                  Unsplash
                </Link>
                <Tooltip
                  title="Il est plus optimal de faire votre recherche en anglais"
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <IconButton
                    size="small"
                    sx={{
                      marginLeft: '.5rem',
                    }}
                  >
                    <InfoIcon
                      fontSize="small"
                      sx={{
                        color: 'primary.main',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            }
            name="imageSearch"
            placeholder="fruit cake..."
            onChange={(e) => onChangeInput(e)}
            value={search}
            onKeyDown={handleSearch}
            marginBottom="0"
            endAdornment={
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  handleSearch();
                }}
              >
                <SearchIcon
                  sx={{
                    color: 'primary.main',
                    fontSize: '1.5rem',
                  }}
                />
              </Box>
            }
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginTop: '2rem',
            height: '100%',
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              overflowY: 'auto',
              maxHeight: '100%',
              paddingRight: '1rem',
              height: {
                xs: 'calc(100% - 12rem)',
                md: 'calc(100% - 10rem)',
              },
              '&::-webkit-scrollbar': {
                width: '6px',
                backgroundColor: '#F5F5F5',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                backgroundColor: '#aaa',
              },
            }}
          >
            {images.map((image) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={image.id}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    opacity: 0.8,
                    '& span': {
                      opacity: 1,
                    },
                    '& img': {
                      filter: 'brightness(0.5)',
                    },
                  },
                }}
              >
                <img
                  src={image.urls.thumb}
                  alt={image.alt_description}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                    aspectRatio: 1,
                    transition: 'all .2s ease-in-out',
                  }}
                  onClick={() => onChangeImage(image)}
                />
                <Typography
                  component="span"
                  sx={{
                    fontSize: '.7rem',
                    fontWeight: 500,
                    position: 'absolute',
                    bottom: '.5rem',
                    left: '1.75rem',
                    right: '.5rem',
                    opacity: 0,
                    transition: 'all .2s ease-in-out',
                    color: 'white',
                  }}
                >
                  Photo by{' '}
                  <Link
                    target="_blank"
                    href={
                      image.user.links.html +
                      '?utm_source=The_Kitchen_Book&utm_medium=referral'
                    }
                    style={{
                      color: 'white',
                    }}
                  >
                    {image.user.name || image.user.username}
                  </Link>
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnsplashModal;

