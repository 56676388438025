import Home from './components/Home/Home';
import Account from './components/Account/Account';
import Family from './components/Family/Family';
import AddRecipe from './components/AddRecipe/AddRecipe';
import RecipesPage from './components/Recipes/RecipesPage';
import RecipePage from './components/Recipe/RecipePage';
import Page404 from './components/Page404/Page404';
import Terms from './components/Terms/Terms';
import Privacy from './components/Terms/RGPD';

const routes: any[] = [
  {
    path: '/',
    component: Home,
    connected: false,
  },
  {
    path: '/',
    component: Account,
    connected: true,
  },
  {
    path: '/account',
    component: Account,
    connected: true,
  },
  {
    path: '/family/:id',
    component: Family,
    connected: true,
  },
  {
    path: '/add-recipe/:id',
    component: AddRecipe,
    connected: true,
  },
  {
    path: '/recipes',
    component: RecipesPage,
  },
  {
    path: '/recipes/:id',
    component: RecipePage,
  },
  {
    path: '/terms',
    component: Terms,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '*',
    component: Page404,
  },
];

export default routes;

