import React from 'react';
import { Grid, Typography } from '@mui/material';
import Navbar from '../Templates/Navbar/Navbar';
import Footer from '../Templates/Footer/Footer';
import Title from '../Templates/Title/Title';

const Privacy = () => (
  <Grid container>
    <Navbar />
    <Grid
      container
      sx={{
        padding: { xs: '3rem 2rem', md: '4.75rem 2rem' },
        maxWidth: '1150px !important',
        margin: '0 auto',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: 'fit-content',
          marginBottom: '3rem',
        }}
      >
        <Title title="Politique de Confidentialité - RGPD" variant="h1" />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Le site web The Kitchen Book est détenu par The Kitchen Book, qui est
          un contrôleur de données de vos données personnelles.
          <br />
          <br />
          Nous avons adopté cette politique de confidentialité, qui détermine la
          manière dont nous traitons les informations collectées par The Kitchen
          Book, qui fournit également les raisons pour lesquelles nous devons
          collecter certaines données personnelles vous concernant. Par
          conséquent, vous devez lire cette politique de confidentialité avant
          d&rsquo;utiliser le site web de The Kitchen Book.
          <br />
          <br />
          Nous prenons soin de vos données personnelles et nous nous engageons à
          en garantir la confidentialité et la sécurité.
        </Typography>
        <Title
          small
          title="Les informations personnelles que nous collectons :"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Lorsque vous visitez le The Kitchen Book, nous recueillons
          automatiquement certains certains des cookies installés sur votre
          appareil, pour le fonctionnement de l&rsquo;application. En outre,
          lorsque vous naviguez sur le site, nous recueillons des informations
          sur les pages web ou les produits individuels que vous consultez, sur
          les sites web ou les termes de recherche qui vous ont renvoyé au site
          et sur la manière dont vous interagissez avec le site, de manière
          anonyme. Nous désignons ces informations collectées automatiquement
          par le terme &rdquo;informations sur les appareils&rdquo;. En outre,
          nous pourrions collecter les données personnelles que vous nous
          fournissez (y compris, mais sans s&rsquo;y limiter, le nom, le prénom,
          l&rsquo;adresse, les informations de paiement, etc.) lors de
          l&rsquo;inscription afin de pouvoir exécuter le contrat.
        </Typography>
        <Title
          small
          title="Pourquoi traitons-nous vos données ?"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Notre priorité absolue est la sécurité des données des clients et, à
          ce titre, nous ne pouvons traiter que des données minimales sur les
          utilisateurs, uniquement dans la mesure où cela est absolument
          nécessaire pour maintenir le site web. Les informations collectées
          automatiquement sont utilisées uniquement pour identifier les cas
          potentiels d&rsquo;abus et établir des informations statistiques
          concernant l&rsquo;utilisation du site web. Ces informations
          statistiques ne sont pas autrement agrégées de manière à identifier un
          utilisateur particulier du système.
          <br />
          <br />
          Vous pouvez visiter le site web sans nous dire qui vous êtes ni
          révéler d&rsquo;informations, par lesquelles quelqu&rsquo;un pourrait
          vous identifier comme un individu spécifique et identifiable.
          Toutefois, si vous souhaitez utiliser certaines fonctionnalités du
          site web, ou si vous souhaitez recevoir notre lettre
          d&rsquo;information ou fournir d&rsquo;autres détails en remplissant
          un formulaire, vous pouvez nous fournir des données personnelles,
          telles que votre e-mail, votre prénom, votre nom, et votre pseudo
          utilisé sur le site. Vous pouvez choisir de ne pas nous fournir vos
          données personnelles, mais il se peut alors que vous ne puissiez pas
          profiter de certaines fonctionnalités du site web. Par exemple, vous
          ne pourrez pas créer de compte, recevoir notre bulletin
          d&rsquo;information ou nous contacter directement à partir du site
          web. Les utilisateurs qui ne savent pas quelles informations sont
          obligatoires sont invités à nous contacter via
          contact@thekitchenbook.fr.
        </Typography>
        <Title
          small
          title="Vos droits :"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Si vous êtes un résident européen, vous disposez des droits suivants
          liés à vos données personnelles :
          <br />
          <br />
          Le droit d&rsquo;être informé.
          <br />
          Le droit d&rsquo;accès.
          <br />
          Le droit de rectification.
          <br />
          Le droit à l&rsquo;effacement.
          <br />
          Le droit de restreindre le traitement.
          <br />
          Le droit à la portabilité des données.
          <br />
          Le droit d&rsquo;opposition.
          <br />
          Les droits relatifs à la prise de décision automatisée et au
          profilage.
          <br />
          <br />
          Si vous souhaitez exercer ce droit, veuillez nous contacter via les
          coordonnées ci-dessous.
          <br />
          <br />
          En outre, si vous êtes un résident européen, nous notons que nous
          traitons vos informations afin d&rsquo;exécuter les contrats que nous
          pourrions avoir avec vous (par exemple, si vous passez une commande
          par le biais du site), ou autrement pour poursuivre nos intérêts
          commerciaux légitimes énumérés ci-dessus. En outre, veuillez noter que
          vos informations pourraient être transférées en dehors de
          l&rsquo;Europe, y compris au Canada et aux États-Unis.
        </Typography>
        <Title
          small
          title="Liens vers d'autres sites web :"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Notre site web peut contenir des liens vers d&rsquo;autres sites web
          qui ne sont pas détenus ou contrôlés par nous. Sachez que nous ne
          sommes pas responsables de ces autres sites web ou des pratiques de
          confidentialité des tiers. Nous vous encourageons à être attentif
          lorsque vous quittez notre site web et à lire les déclarations de
          confidentialité de chaque site web susceptible de collecter des
          informations personnelles.
        </Typography>
        <Title
          small
          title="Sécurité de l'information :"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Nous sécurisons les informations que vous fournissez sur des serveurs
          informatiques dans un environnement contrôlé et sécurisé, protégé
          contre tout accès, utilisation ou divulgation non autorisés. Nous
          conservons des garanties administratives, techniques et physiques
          raisonnables pour nous protéger contre tout accès, utilisation,
          modification et divulgation non autorisés des données personnelles
          sous son contrôle et sa garde. Toutefois, aucune transmission de
          données sur Internet ou sur un réseau sans fil ne peut être garantie.
        </Typography>
        <Title
          small
          title="Divulgation légale :"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Nous divulguerons toute information que nous collectons, utilisons ou
          recevons si la loi l&rsquo;exige ou l&rsquo;autorise, par exemple pour
          nous conformer à une citation à comparaître ou à une procédure
          judiciaire similaire, et lorsque nous pensons de bonne foi que la
          divulgation est nécessaire pour protéger nos droits, votre sécurité ou
          celle d&rsquo;autrui, enquêter sur une fraude ou répondre à une
          demande du gouvernement.
        </Typography>
        <Title
          small
          title="Informations de contact :"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Si vous souhaitez nous contacter pour comprendre davantage la présente
          politique ou si vous souhaitez nous contacter concernant toute
          question relative aux droits individuels et à vos informations
          personnelles, vous pouvez envoyer un courriel à
          contact@thekitchenbook.fr.
        </Typography>
      </Grid>
    </Grid>
    <Footer />
  </Grid>
);

export default Privacy;

