/* eslint-disable indent */
import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import Title from '../../Templates/Title/Title';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import { openModal } from '../../../redux/slices/recipe/recipeSlice';
import GradeRow from './GradeRow/GradeRow';
import { STEPS_GRADES } from '../../../utils/utils';

interface GradeModalProps {
  open: boolean;
  onClose: () => void;
}

const GradeModal: React.FC<GradeModalProps> = ({ open, onClose }) => {
  const user = useSelector((state: RootState) => state.userReducer.user);
  const dispatch = useDispatch();

  if (!user) return null;

  const addRecipe = () => {
    dispatch(openModal());
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Créer une famille"
      aria-describedby="Ce modal sert à créer une famille"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          background: 'white',
          borderRadius: 3,
          width: '90%',
          maxWidth: 700,
          height: '80vh',
          maxHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '6%',
            right: '2rem',
            cursor: 'pointer',
          }}
        >
          <CloseRoundedIcon onClick={onClose} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.5rem',
            borderBottom: '1px solid #DFDFDF',
          }}
        >
          <Title title={'Grades et statuts'} small />
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              lineHeight: 'normal',
              marginTop: '1.5rem',
              textAlign: 'center',
              maxWidth: '80%',
            }}
          >
            Gravis les échelons et deviens un Chef Étoilé d’exception !
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: { xs: '1rem 2rem 2rem', sm: '1rem 3rem 3rem ' },
            paddingBottom: '3rem',
            overflowY: 'auto',
            height: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {STEPS_GRADES.map((step, index) => {
              const nbRecipeForPreviousGrade =
                index > 0 ? STEPS_GRADES[index - 1].nbRecipesRequired : 0;
              const nbRecipeForGrade = step.nbRecipesRequired;

              const nbRecipesForNextGrade =
                index < STEPS_GRADES.length - 1
                  ? STEPS_GRADES[index + 1].nbRecipesRequired
                  : nbRecipeForGrade;

              const userRecipes = user.recipes.length;
              const isFilled = userRecipes >= nbRecipeForGrade;
              let percentage;

              if (isFilled) {
                if (userRecipes >= nbRecipesForNextGrade) {
                  percentage = 100;
                } else {
                  percentage =
                    Math.round(
                      ((userRecipes - nbRecipeForGrade) /
                        (nbRecipesForNextGrade - nbRecipeForGrade)) *
                        100
                    ) * 2;

                  percentage = percentage > 100 ? 100 : percentage;
                }
              } else {
                percentage =
                  (Math.round(
                    ((userRecipes - nbRecipeForPreviousGrade) /
                      (nbRecipeForGrade - nbRecipeForPreviousGrade)) *
                      100
                  ) -
                    50) *
                  2;
              }

              return (
                <GradeRow
                  key={step.grade}
                  isFirst={index === 0}
                  isLast={index === STEPS_GRADES.length - 1}
                  step={index + 1}
                  title={step.title}
                  grade={step.grade}
                  description={step.description}
                  isFilled={isFilled}
                  percentage={percentage}
                />
              );
            })}
          </Box>

          <ButtonFilled
            title="Ajoutez une recette"
            onClick={() => {
              onClose();
              addRecipe();
            }}
            sx={{
              marginTop: 'auto',
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default GradeModal;

