import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import CustomOutlinedInput from '../.././Templates/Inputs/CustomOutlinedInput';
import CustomLabel from '../../Templates/Inputs/CustomLabel';

interface Step1Props {
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  title: string;
  introduction: string;
  titleError: string;
  introductionError: string;
  image: string;
  onChangeImage: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  imageError: string;
  openUnsplash: () => void;
}

const Step1: React.FC<Step1Props> = ({
  onChange,
  title,
  introduction,
  titleError,
  introductionError,
  image,
  onChangeImage,
  imageError,
  openUnsplash,
}) => {
  return (
    <Box
      sx={{
        marginTop: { xs: '2rem', sm: '3rem' },
        height: 'calc(65% - 2rem)',
        overflow: 'auto',
        paddingRight: '1rem',
        '&::-webkit-scrollbar': {
          width: '6px',
          backgroundColor: '#F5F5F5',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          backgroundColor: '#aaa',
        },
      }}
    >
      <CustomOutlinedInput
        label="Titre de la recette :"
        name="title"
        onChange={(e) => onChange(e)}
        errorMessage={titleError && !title ? titleError : null}
        value={title || ''}
        error={!!titleError && !title}
      />

      <CustomOutlinedInput
        label="Courte description (facultative) :"
        placeholder="Exemple : A servir chaud, fonctionne bien avec du vin blanc."
        name="introduction"
        onChange={(e) => onChange(e)}
        errorMessage={
          introductionError && !introduction ? introductionError : null
        }
        value={introduction || ''}
        error={!!introductionError && !introduction}
        multiline
        rows={4}
      />

      <CustomLabel label="Image de la recette :" />
      <ButtonFilled
        title={"Librairie d'images"}
        component="span"
        onClick={openUnsplash}
        sx={{
          marginBottom: '1rem',
        }}
      />
      <Box
        sx={{
          cursor: 'pointer',
        }}
      >
        <input
          accept="image/png, image/jpeg"
          id="contained-button-file"
          type="file"
          style={{
            display: 'none',
          }}
          onChange={(e) => {
            onChangeImage(e);
          }}
        />
        <label
          htmlFor="contained-button-file"
          style={{
            cursor: 'pointer',
          }}
        >
          <div className="input-preview">
            {image && image !== '' && (
              <img
                alt="Image"
                src={image}
                style={{
                  maxWidth: 'max(50%, 200px)',
                  width: '100%',
                  marginBottom: '.5rem',
                }}
              />
            )}
          </div>

          <ButtonFilled title={'Uploader une image'} component="span" grey />
        </label>
      </Box>
      {imageError && !image && (
        <Typography
          variant="body1"
          sx={{
            fontSize: '.9rem',
            fontWeight: 400,
            marginTop: '.5rem',
            color: 'error.main',
          }}
        >
          {imageError}
        </Typography>
      )}
    </Box>
  );
};

export default Step1;

