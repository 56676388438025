import React, { createContext } from 'react';
import CustomAlert from '../components/Alert/CustomAlert';

interface AlertContextProps {
  message: string | null;
  severity: severity;
  duration: number;
  isOpen: boolean;
}

type severity = 'success' | 'info' | 'warning' | 'error';

export const AlertContext = createContext({
  alert: {
    message: null,
    severity: 'info' as severity,
    duration: 5000,
    isOpen: false,
  },
  hideAlert: () => {
    console.log('hideAlert');
  },
  showAlert: (message: string | null, severity: severity, timeout?: number) => {
    console.log('showAlert', message, severity, timeout);
  },
});

const AlertContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [alert, setAlert] = React.useState<AlertContextProps>({
    message: null,
    severity: 'info' as severity,
    duration: 5000,
    isOpen: false,
  });

  const showAlert = (
    message: string,
    severity: severity = 'info',
    timeout?: number
  ) => {
    setAlert({ message, severity, isOpen: true, duration: timeout || 5000 });
  };

  const hideAlert = () => {
    setAlert((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, hideAlert }}>
      {children}
      <CustomAlert />
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;

