import { Box, Typography, Skeleton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import { FamilyType } from '../../../types/Family.type';

const getFirstLetters = (title) => {
  let firstLetters: string =
    (Array.from(title.split(' ')[0] || '')[0] as string) || '';
  const hasMultipleWords = title.split(' ').length > 1;
  if (hasMultipleWords) {
    firstLetters += Array.from(title.split(' ')[1] || '')[0];
  }
  return firstLetters;
};

const sliceName = (title: string) => {
  // if (Array.from(title).length > 10) {
  //   return title.slice(0, 10) + '...';
  // } else
  return title;
};

interface FamilyCardProps {
  family?: FamilyType;
  isLoadingCard?: boolean;
}

const FamilyCard: React.FC<FamilyCardProps> = ({ family, isLoadingCard }) => {
  return (
    <Link
      to={`/family/${family?.id}`}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <Box
        sx={{
          padding: '1.5rem 2rem 1.5rem 1.5rem',
          borderRadius: '8px',
          cursor: 'pointer',
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.07)',
          transition: 'all .2s ease-in-out',

          '&:hover': {
            boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 70,
              height: 70,
              background: '#F4F7F8',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: 30,
                fontFamily: 'Poetsen One',
                color: 'primary.main',
                textTransform: 'uppercase',
              }}
            >
              {isLoadingCard ? '' : getFirstLetters(family?.title)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              maxWidth: 'calc(100% - 70px)',
              paddingLeft: '2rem',
              width: '100%',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: { xs: '1.5rem', sm: '1.25rem' },
                fontWeight: 500,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              {isLoadingCard ? (
                <Skeleton variant="text" width={150} />
              ) : (
                `Famille ${sliceName(family?.title)}`
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: '1.15rem', sm: '.9rem' },
                  fontWeight: 500,
                  marginBottom: '.5rem',
                  marginRight: '1rem',
                }}
              >
                {isLoadingCard ? (
                  <Skeleton variant="text" width={100} />
                ) : (
                  `${family?.recipes.length || '0'} recette${
                    family?.recipes.length > 1 ? 's' : ''
                  }`
                )}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: '1.15rem', sm: '.9rem' },
                  fontWeight: 500,
                  marginBottom: '.5rem',
                }}
              >
                {isLoadingCard ? (
                  <Skeleton variant="text" width={100} />
                ) : (
                  `${family?.members.length || '0'} membre${
                    family?.members.length > 1 ? 's' : ''
                  }`
                )}
              </Typography>
            </Box>
            {isLoadingCard ? (
              <Skeleton
                variant="rectangular"
                height={'2rem'}
                width={'100%'}
                sx={{
                  borderRadius: 1,
                  marginTop: '.5rem',
                }}
              />
            ) : (
              <ButtonFilled
                title={'Voir les recettes'}
                grey
                path={`/family/${family?.id}`}
                fullWidth
                smallRadius
                smallPadding
                margin=".5rem 0 0"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default FamilyCard;

