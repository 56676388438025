import React from 'react';
import { Typography } from '@mui/material';

interface CustomLabelProps {
  label: string | React.ReactElement;
  marginBottom?: string;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ label, marginBottom }) => {
  return (
    <Typography
      variant="h3"
      sx={{
        fontSize: '1.15rem',
        fontWeight: 500,
        marginBottom: marginBottom || '.75rem',
      }}
    >
      {label}
    </Typography>
  );
};

export default CustomLabel;

