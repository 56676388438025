import React from 'react';
import { RowProps } from '../HowItWorks.type';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const Row: React.FC<RowProps> = ({
  title,
  description,
  Image,
  isFirst,
  isRevert,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));

  const lineSx = {
    width: mobile ? 2 : 4,
    backgroundColor: mobile ? '#1F202617' : 'text.primary',
    position: 'absolute',
    left: mobile ? '5%' : '50%',
    height: '50%',
    transform: `translateX(-${mobile ? 1 : 2}px)`,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        flexDirection: isRevert && !mobile ? 'row-reverse' : 'row',
        padding: '1.5rem 0',
        transform: mobile ? 'translateX(-10%)' : '',
      }}
    >
      {!mobile && (
        <Box
          sx={{
            width: '40%',
            display: 'flex',
            justifyContent: isRevert ? 'flex-start' : 'flex-end',
            alignItems: 'center',
          }}
        >
          <Image
            sx={{
              fontSize: '10rem',
              maxWidth: mobile ? '50px' : tablet ? '65px' : '80px',
              fill: '#EF233C',
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          width: mobile ? '10%' : '15%',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {!isFirst && (
          <Box
            sx={{
              ...lineSx,
              bottom: '50%',
            }}
          />
        )}

        <Box
          sx={{
            width: mobile ? 11 : 17,
            height: mobile ? 11 : 17,
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            zIndex: 1,
          }}
        />

        <Box
          sx={{
            ...lineSx,
            top: '50%',
          }}
        />
      </Box>
      <Box
        sx={{
          width: mobile ? '90%' : '40%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            textAlign: 'left',
            justifyContent: 'center',
            flexDirection: 'column',
            transform: mobile ? 'translateX(1.5rem)' : '',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '2rem',
              textAlign: 'left',
              marginBottom: '1rem',
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontSize: '1.25rem',
              marginBottom: '1rem',
              fontWeight: 400,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Row;

