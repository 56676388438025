/* eslint-disable indent */
// IMPORT GENERAL
import React, { useState, useEffect } from 'react';
import { Fire } from '../../services';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import CardGeneral from '../Templates/Cards/CardGeneral';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { updateRecipe, openModal } from '../../redux/slices/recipe/recipeSlice';
import { RootState } from '../../redux/store/store';
import { getUserGrade } from '../../utils/utils';
import DeleteIcon from '@mui/icons-material/Delete';

// IMPORT COMPONENTS
import Navbar from '../Templates/Navbar/Navbar';
import { Grid, Box, Typography } from '@mui/material';

// IMPORT IMAGES
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';
import { updateUser } from '../../redux/slices/user/userSlice';
import { useAlert } from '../../hooks/useAlert';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const verifyQuantity = (quantity: any) => {
  return !isNaN(quantity) && quantity !== 0 && quantity !== '';
};

const RecipePage: React.FC = () => {
  const [recipe, setRecipe] = useState<any>(null);
  const [portions, setPortions] = useState<number>(recipe?.portions.quantity);
  const [portionCoef, setPortionCoef] = useState<number>(1);
  const [totalTime, setTotalTime] = useState<string | JSX.Element>('');
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userReducer.user);
  const { showAlert } = useAlert();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const recipeLink = window.location.href;

  const theme = useTheme();
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getAddTime: (time1: number, time2: number) => string | JSX.Element = (
    time1: number,
    time2: number
  ) => {
    const time = time1 + time2;
    return getLiteralTime(time);
  };

  const getLiteralTime: (time: number) => string | JSX.Element = (
    time: number
  ) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours !== 0 ? hours + 'h' : ''} ${
      minutes !== 0 ? `${minutes} ` + 'minutes' : ''
    }`;
  };

  const getRecipeInfos = async (id: string) => {
    const recipeRef = await Fire.store().collection('recipes').doc(id);
    const recipeInfos = await Fire.doc(recipeRef);

    if (!recipeInfos) {
      navigate('/recipes');
      return;
    }
    recipeInfos.ingredients.sort();
    setRecipe(recipeInfos);
  };

  const deleteRecipe = async (e) => {
    e.stopPropagation();
    if (!id) return;
    if (recipe.author?.id !== user?.id) return;

    const recipeId = id;
    const userNewGrade = getUserGrade(user);

    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette recette ?')) {
      const newRecipes = user?.recipes.filter((recipe) => recipe !== recipeId);
      await dispatch(updateUser({ recipes: newRecipes, grade: userNewGrade }));

      // delete recipe from families recipes

      await Fire.delete('recipes', recipeId);
      showAlert('La recette a bien été supprimée', 'success');
      navigate('/');
    }
  };

  useEffect(() => {
    getRecipeInfos(id as string);
  }, []);

  useEffect(() => {
    if (recipe) {
      setTotalTime(getAddTime(recipe.preparationTime, recipe.cookingTime));
      setPortions(parseInt(recipe.portions.quantity));
    }
  }, [recipe]);

  return (
    <Fade in timeout={500}>
      <Box minHeight="100vh">
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            padding: mobile ? '1rem 0 2rem' : '3rem 4rem',
            maxWidth: '1350px !important',
            margin: 'auto',
            width: '100%',
            background: 'white',
          }}
        >
          <Grid
            item
            xs={12}
            container
            sx={{
              justifyContent: 'space-between',
              width: '100%',
              padding: { xs: '0 2rem 2rem', sm: '0 0 3rem 0' },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white' }}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Retour
            </Button>
            <Box>
              {(!recipe?.author?.id ||
                (user?.id &&
                  recipe?.author?.id &&
                  user?.id === recipe?.author?.id)) && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    dispatch(
                      updateRecipe({ recipe: recipe, isUpdating: true })
                    );
                    dispatch(openModal());
                  }}
                  sx={{
                    marginRight: '1rem',
                  }}
                  endIcon={<EditIcon />}
                >
                  modifier
                </Button>
              )}
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  if (!recipeLink) return;
                  if (recipeLink === '') return;
                  setIsCopied(true);
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(recipeLink);
                  } else {
                    document.execCommand('copy', true, recipeLink);
                  }
                  showAlert('Lien copié ! 🎉', 'success', 2000);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 2000);
                }}
                endIcon={isCopied ? '' : <ContentCopyIcon fontSize="small" />}
              >
                {isCopied ? 'Lien copié ! 🎉' : 'Copier le lien'}
              </Button>
            </Box>
          </Grid>
          <Grid container item sm={12} md={5}>
            <Grid
              item
              xs={12}
              sx={{
                padding: {
                  xs: '0 2rem',
                  sm: 'initial',
                },
              }}
            >
              <Box
                sx={{
                  background: `center / cover no-repeat url(${recipe?.image})`,
                  paddingTop: { xs: '70%', sm: '50%', md: '75%' },
                  width: '100%',
                  borderRadius: '7px',
                }}
              />
              <Box
                sx={{
                  flexDirection: 'column',
                  width: '100%',
                  margin: '2rem 0',
                  display: {
                    xs: 'flex',
                    md: 'none',
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 400,
                    fontFamily: 'Poetsen One',
                    color: 'primary.main',
                    fontSize: '2rem',
                    marginBottom: '1rem',
                  }}
                >
                  {recipe?.title}
                </Typography>
                <Box display="flex" alignItems="center">
                  <WatchLaterIcon
                    sx={{
                      color: 'text.primary',
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      marginLeft: '.75rem',
                      color: 'text.primary',
                      fontWeight: 500,
                      fontSize: '1.2rem',
                    }}
                  >
                    {totalTime}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: { xs: '1rem', md: '2rem' },
              }}
            >
              <CardGeneral noRadius={!!mobile} padding={'2rem'}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ShoppingCartIcon
                      color="primary"
                      sx={{ marginRight: '.7rem', fontSize: '1.9rem' }}
                    />
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400,
                        fontFamily: 'Poetsen One',
                        color: 'primary.main',
                        fontSize: { xs: '1.75rem', sm: '1.4rem' },
                      }}
                    >
                      Ingrédients :
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '2rem',
                      backgroundColor: 'primary.main',
                      borderRadius: '10px',
                      padding: { xs: '.65rem 1rem', sm: '.5rem 1rem' },
                    }}
                  >
                    <Box
                      sx={{
                        padding: '0 2rem',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: portions >= 1 ? 'pointer' : 'default',
                      }}
                      onClick={() => {
                        setPortionCoef(() =>
                          portions > 1
                            ? (portions - 1) / recipe?.portions.quantity
                            : portions / recipe?.portions.quantity
                        );
                        setPortions((prev) => (prev > 1 ? prev - 1 : prev));
                      }}
                    >
                      <RemoveIcon
                        sx={{
                          color: 'white',
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '0 1.5rem',
                      }}
                    >
                      <Typography
                        variant="h6"
                        component={'span'}
                        sx={{
                          fontSize: { xs: '1.3rem', sm: '1.1rem' },
                          color: 'white',
                          fontWeight: 500,
                        }}
                      >
                        {portions} {recipe?.portions.measure}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        padding: '0 2rem',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setPortionCoef(
                          (portions + 1) / recipe?.portions.quantity
                        );
                        setPortions((prev) => {
                          return prev + 1;
                        });
                      }}
                    >
                      <AddIcon sx={{ color: 'white' }} />
                    </Box>
                  </Box>
                </Box>
                <Box width="100%" marginTop="2rem" padding="0 .25rem">
                  {recipe?.ingredients.map((ingredient: any) => (
                    <Box
                      key={ingredient.id}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginTop: '1rem',
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          fontSize: { xs: '1.2rem', sm: '1.05rem' },
                        }}
                      >
                        -
                      </Typography>
                      {verifyQuantity(ingredient.quantity) && (
                        <Typography
                          variant="body1"
                          sx={{
                            marginLeft: '.75rem',
                            color: theme.palette.primary.main,
                            fontWeight: 500,
                            fontSize: { xs: '1.2rem', sm: '1.05rem' },
                          }}
                        >
                          {Math.floor(ingredient.quantity * portionCoef * 100) /
                            100 !==
                          0
                            ? ingredient.quantity < 10
                              ? Math.floor(
                                  ingredient.quantity * portionCoef * 100
                                ) / 100
                              : Math.floor(ingredient.quantity * portionCoef)
                            : 1}
                        </Typography>
                      )}
                      {ingredient.unit !== undefined && ingredient.unit && (
                        <Typography
                          variant="body1"
                          sx={{
                            marginLeft: '.25rem',
                            fontWeight: 500,
                            fontSize: { xs: '1.2rem', sm: '1.05rem' },
                          }}
                        >
                          {ingredient.unit}
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        sx={{
                          marginLeft: '.25rem',
                          textTransform: !ingredient.quantity
                            ? 'capitalize'
                            : 'lowercase',
                          fontWeight: 500,
                          fontSize: { xs: '1.2rem', sm: '1.05rem' },
                        }}
                      >
                        {ingredient.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardGeneral>
            </Grid>
          </Grid>
          <Grid
            item
            sm={12}
            md={7}
            sx={{
              paddingTop: {
                xs: '3rem',
                md: '0',
              },
              paddingLeft: {
                sm: 0,
                md: 'inherit',
              },
            }}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 400,
                  fontFamily: 'Poetsen One',
                  color: 'primary.main',
                  fontSize: '2rem',
                }}
              >
                {recipe?.title}
              </Typography>
              <Box display="flex" alignItems="center">
                <WatchLaterIcon
                  sx={{
                    color: 'text.primary',
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    marginLeft: '.75rem',
                    color: 'text.primary',
                    fontWeight: 500,
                  }}
                >
                  {totalTime}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: {
                  xs: 'flex',
                  md: 'none',
                },
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LocalDiningIcon
                color="primary"
                sx={{ marginRight: '.7rem', fontSize: '2rem' }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  fontFamily: 'Poetsen One',
                  color: 'primary.main',
                  fontSize: { xs: '1.75rem', sm: '1.4rem' },
                }}
              >
                Préparation :
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                marginTop: '2rem',
                padding: { xs: '0 2rem ', sm: 0 },
              }}
            >
              <CardGeneral>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: { xs: 'space-around', sm: 'center' },
                    flexWrap: 'wrap',
                    margin: '0 auto',
                    padding: {
                      xs: '.5rem 1rem',
                      sm: '0',
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: '100%', sm: 'fit-content' },
                      display: 'flex',
                      flexDirection: { xs: 'row', sm: 'column' },
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: { xs: '1rem', sm: '0' },
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: '1.15rem', sm: '1rem' },
                      }}
                    >
                      Temps total :
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '1.15rem', sm: '1rem' },
                        marginLeft: { xs: '.5rem', sm: '0' },
                      }}
                    >
                      {totalTime}{' '}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '1px',
                      backgroundColor: '#DFDFDF',
                      margin: '0 7%',
                      display: { xs: 'none', sm: 'block' },
                    }}
                  />
                  <Box
                    sx={{
                      width: 'fit-content',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: '1.15rem', sm: '1rem' },
                      }}
                    >
                      Préparation :
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '1.15rem', sm: '1rem' },
                      }}
                    >
                      {getLiteralTime(recipe?.preparationTime || 0)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '1px',
                      backgroundColor: '#DFDFDF',
                      margin: '0 7%',
                      display: { xs: 'none', sm: 'block' },
                    }}
                  />
                  <Box
                    sx={{
                      width: 'fit-content',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: '1.15rem', sm: '1rem' },
                      }}
                    >
                      Cuisson :
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        fontSize: { xs: '1.15rem', sm: '1rem' },
                      }}
                    >
                      {getLiteralTime(recipe?.cookingTime || 0)}
                    </Typography>
                  </Box>
                </Box>
              </CardGeneral>
            </Box>
            <Box
              sx={{
                width: '100%',
                marginTop: {
                  xs: '0',
                  md: '2rem',
                },
              }}
            >
              <CardGeneral noBg={!!mobile}>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: '100%',
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}
                >
                  <LocalDiningIcon
                    color="primary"
                    style={{ marginRight: '.7rem' }}
                    fontSize="large"
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      fontFamily: 'Poetsen One',
                      color: 'primary.main',
                      fontSize: '1.4rem',
                    }}
                  >
                    Préparation :
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginTop: { xs: 0, md: '2rem' },
                    padding: '0 .25rem',
                  }}
                >
                  {recipe?.recipe.map((step: any, index: any) => (
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      key={'step_recipe_' + index}
                      marginTop="1rem"
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          marginLeft: '.75rem',
                          color: theme.palette.text.primary,
                          fontWeight: 400,
                          fontSize: { xs: '1.2rem', sm: '1.05rem' },
                        }}
                      >
                        {step.name || step || ''}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardGeneral>
            </Box>
            {recipe?.introduction && (
              <Box
                sx={{
                  width: '100%',
                  marginTop: '2rem',
                }}
              >
                <CardGeneral noBg>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400,
                        fontFamily: 'Poetsen One',
                        color: 'primary.main',
                        fontSize: '1.4rem',
                      }}
                    >
                      Description :
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: '100%',
                      padding: '0 .25rem',
                      marginTop: '1rem',
                    }}
                  >
                    <Box width="100%" display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 400,
                          fontSize: { xs: '1.2rem', sm: '1.05rem' },
                        }}
                      >
                        {recipe?.introduction || ''}
                      </Typography>
                    </Box>
                  </Box>
                </CardGeneral>
              </Box>
            )}
          </Grid>
          {recipe?.author?.id === user?.id && (
            <Box
              sx={{
                width: '100%',
                marginTop: '4rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ButtonFilled
                onClick={(e) => deleteRecipe(e)}
                title="Supprimer la recette"
                red
                variant="text"
                startIcon={<DeleteIcon />}
              />
            </Box>
          )}
        </Grid>

        {/* <Grid
          item
          container
          xs={12}
          sx={{
            padding: mobile ? '1rem 2rem 2rem' : '3rem 4rem',
            borderTop: '1px solid #DFDFDF',
            maxWidth: 1500,
            margin: 'auto',
            width: '100%',
            background: 'white',
          }}
        >
          a
        </Grid> */}
      </Box>
    </Fade>
  );
};

export default RecipePage;

