import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Fire, Tools } from '../services';
import { useAlert } from './useAlert';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';
import { updateUser } from '../redux/slices/user/userSlice';
import { initialRecipe, closeModal } from '../redux/slices/recipe/recipeSlice';
import { Category } from '../types/RecipeProps.type';
import { getUserGrade } from '../utils/utils';

const CATEGORIES: Category[] = [
  'Entrées',
  'Plats',
  'Desserts',
  'Boissons',
  'Sauces',
  'Vegan',
  'Veggie',
  'Autre',
];

const STEPS = ['step 1', 'step 2', 'step 3', 'step 4', 'step 5'];

const useCreateRecipe: any = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [imageHasChanged, setImageHasChanged] = React.useState(false);
  const { showAlert } = useAlert();
  const user = useSelector((state: any) => state.userReducer.user);
  const isModalOpen = useSelector(
    (state: RootState) => state.recipeReducer.isModalOpen
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newDate = new Date();
  const date = newDate.getTime();

  const recipe = useSelector((state: RootState) => state.recipeReducer.recipe);

  const schema = yup.object().shape({
    title: yup
      .string()
      .required('Veuillez renseigner un titre à votre recette'),
    introduction: yup.string(),
    image: yup.string().required('Veuillez ajouter une image à votre recette'),
    imageName: yup.string(),
    ingredients: yup
      .array()
      .of(
        yup.object().shape({
          quantity: yup.number().nullable(),
          unit: yup.string().nullable(),
          name: yup.string().nullable(),
          id: yup.string().nullable(),
        })
      )
      .min(2, 'Veuillez renseigner au moins deux ingrédients à votre recette'),
    recipe: yup.array().of(
      yup
        .object()
        .shape({
          name: yup
            .string()
            .required(
              'Veuillez renseigner au moins une étape à votre recette de cuisine'
            ),
          id: yup.string().required(),
        })
        .required()
    ),
    categories: yup.array().of(yup.string().oneOf(CATEGORIES)),
    portions: yup.object().shape({
      quantity: yup
        .number()
        .required('Veuillez renseigner le nombre de portions de votre recette'),
      measure: yup.string().required("Veuillez renseigner l'unité de mesure"),
    }),
    preparationTime: yup
      .number()
      .required('Veuillez renseigner le temps de préparation de votre recette'),
    cookingTime: yup
      .number()
      .required('Veuillez renseigner le temps de cuisson de votre recette'),
    author: yup
      .object()
      .shape({
        username: yup.string(),
        grade: yup.string(),
        id: yup.string(),
      })
      .nullable(),
    families: yup.array().of(yup.string().required()),
    isPublic: yup.boolean().required(),
  });

  const {
    values,
    setValues,
    handleSubmit,
    errors,
    validateField,
    validateForm,
    isSubmitting,
  } = useFormik({
    initialValues: initialRecipe,
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: async (values) => {
      let isUpdating = false;
      if (user) {
        setValues({
          ...values,
          author: {
            username: user.username,
            grade: user.grade,
            id: user.id,
          },
        });
      }

      if (values.image !== '') {
        if (imageHasChanged) {
          const link =
            'recipes/' +
            values.title.replace(/\s+/g, '-') +
            '_' +
            date +
            '.png';
          const newImage = await Fire.uploadFile(link, values.image);
          if (newImage.url === 'error' || !newImage.url) {
            showAlert("Une erreur est survenue lors de l'envoi de votre image");
            return;
          }

          setValues({
            ...values,
            image: newImage.url,
            imageName: newImage.name,
          });
          values.image = newImage.url;
          values.imageName = newImage.name;
          setImageHasChanged(false);
        }
      }

      const item = {
        ...values,
        date: new Date(),
        author: {
          username: user?.username,
          grade: user?.grade,
          id: user?.id,
        },
      };
      let isDraft = false;

      if (values.id) {
        isDraft =
          user?.draftRecipes?.filter((draft: any) => draft.id == values.id)
            .length > 0
            ? true
            : false;

        const recipe = await Fire.get('recipes', values.id);
        if (recipe) {
          isUpdating = true;
        } else {
          delete item.id;
        }
      } else {
        delete item.id;
      }

      if (values.isDraft) {
        delete item.isDraft;
      }

      const userNewGrade = getUserGrade(user);

      let newRecipes = user?.recipes;

      if (isUpdating) {
        await Fire.update('recipes', item.id, item).then(async () => {
          if (!newRecipes.includes(item.id)) {
            newRecipes = [...newRecipes, item.id];
          }
        });
      } else {
        await Fire.add('recipes', item).then(async (values) => {
          if (values.id) {
            if (!newRecipes.includes(item.id)) {
              newRecipes = [...newRecipes, values.id];
              item.id = values.id;
            }
          }
        });
      }

      let newDrafts = user?.draftRecipes;

      if (isDraft) {
        // update user and remove from drafts
        newDrafts = user?.draftRecipes?.filter(
          (draft: any) => draft.id !== values.id
        );
      }

      await Fire.update('users', user?.id, {
        recipes: newRecipes,
        grade: userNewGrade,
        draftRecipes: newDrafts,
      });
      dispatch(
        updateUser({
          ...user,
          recipes: newRecipes,
          grade: userNewGrade,
          draftRecipes: newDrafts,
        })
      );

      showAlert(
        isUpdating
          ? 'Votre recette a bien été modifée !'
          : 'Votre recette a bien été ajoutée, merci pour votre contribution !',
        'success'
      );

      setValues(initialRecipe);
      dispatch(closeModal());

      if (window.location.pathname !== '/recipes/' + item.id) {
        navigate('/recipes/' + item.id);
      } else {
        window.location.reload();
      }
    },
  });

  React.useEffect(() => {
    if (recipe) {
      setValues(recipe);
    }
  }, [recipe]);

  React.useEffect(() => {
    if (isModalOpen) {
      // disable scroll when modal is open
      if (!values.title && !values.image) {
        setActiveStep(0);
      }
    }
  }, [isModalOpen]);

  const addIngredient = () => {
    const newId = Date.now().toString();
    const newIngredient = {
      quantity: '',
      unit: '',
      name: '',
      id: newId,
    };
    setValues({
      ...values,
      ingredients: [...values.ingredients, newIngredient],
    });
  };

  const removeIngredient = (index: number) => {
    const newIngredients = values.ingredients.filter(
      (ingredient, i) => i !== index
    );
    setValues({
      ...values,
      ingredients: newIngredients,
    });
  };

  const addStep = (value?: string) => {
    const newStep = {
      name: value || '',
      id: Date.now().toString(),
    };
    setValues({
      ...values,
      recipe: [...values.recipe, newStep],
    });
  };

  const addSteps = (steps: string[]) => {
    const newSteps = steps.map((step, index) => ({
      name: step,
      id: Date.now().toString() + index,
    }));
    setValues({
      ...values,
      recipe: newSteps,
    });
  };

  const removeStep = (index: number) => {
    const newSteps = values.recipe.filter((step, i) => i !== index);
    setValues({
      ...values,
      recipe: newSteps,
    });
  };

  const handleReorderSteps = (steps) => {
    setValues({
      ...values,
      recipe: steps,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    setValues({
      ...values,
      [name]: value ? (type === 'number' ? parseInt(value) : value) : '',
    });
  };

  const handleChangeImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageHasChanged(true);
    try {
      const url = await Tools.requestSmallImage(e);
      setValues({
        ...values,
        image: url,
      });
    } catch (err) {
      const error = err as { message: string };

      showAlert(error.message);
    }
  };

  const handleChangeImageUnsplash = async (url) => {
    try {
      setImageHasChanged(false);
      setValues({
        ...values,
        image: url,
      });
    } catch (err) {
      const error = err as { message: string };

      showAlert(error.message);
    }
  };

  const handleChangeIngredients = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const newIngredients = values.ingredients.map((ingredient, i) => {
      if (i === index) {
        return {
          ...ingredient,
          [name]: value,
        };
      }
      return ingredient;
    });
    setValues({
      ...values,
      ingredients: newIngredients,
    });
  };

  const handleReorderIngredients = (newIngredients) => {
    setValues({
      ...values,
      ingredients: newIngredients,
    });
  };

  const handleChangeSteps = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    const newSteps = values.recipe.map((step, i) => {
      if (i === index) {
        return {
          ...step,
          name: value,
        };
      }
      return step;
    });
    setValues({
      ...values,
      recipe: newSteps,
    });
  };

  const handleChangePortions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      portions: {
        ...values.portions,
        [name]: value,
      },
    });
  };

  const handleChangeCategories = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as Category;
    const newCategories: Category[] = values.categories.includes(
      value as Category
    )
      ? values.categories.filter((category) => category !== value)
      : [...values.categories, value];
    setValues({
      ...values,
      categories: newCategories,
    });
  };

  const handleChangeIsPublic = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValues({
      ...values,
      isPublic: value === 'true' ? true : false,
    });
  };

  const saveInDrafts = async () => {
    const newId = values.id || Date.now().toString();
    if (values.image !== '') {
      if (imageHasChanged) {
        const link =
          'recipes/' + values.title.replace(/\s+/g, '-') + '_' + date + '.png';
        const image = await Fire.uploadFile(link, values.image);
        values.image = image.url;
        values.imageName = image.name;
        setImageHasChanged(false);
      }
    }

    const newRecipe = {
      ...values,
      isDraft: true,
      id: newId,
    };

    if (user) {
      // check if recipe already exists
      const newDraftRecipes = [...(user.draftRecipes || [])];

      if (user.draftRecipes) {
        const index = user.draftRecipes.findIndex(
          (recipe) => recipe.id === newId
        );
        if (index !== -1) {
          newDraftRecipes[index] = newRecipe;
        } else {
          newDraftRecipes.push(newRecipe);
        }
      }

      await Fire.update('users', user.id, {
        draftRecipes: newDraftRecipes,
      });
      dispatch(
        updateUser({
          draftRecipes: newDraftRecipes,
        })
      );
      showAlert('Recette sauvegardée dans les brouillons', 'success');
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      validateField('title');
      validateField('introduction');
      validateField('image');
      if (values.title === '' || values.image === '') {
        return;
      }
    }
    if (activeStep === 1) {
      validateField('ingredients');
      validateField('portions');
      if (values.ingredients.length === 1) {
        return;
      }
      if (
        values.portions.measure === '' ||
        values.portions.quantity === null ||
        !values.portions.quantity ||
        isNaN(values.portions.quantity)
      ) {
        return;
      }
    }

    if (activeStep === 2) {
      validateField('recipe');
      if (values.recipe[0].name === '') {
        return;
      }
    }

    if (activeStep === 3) {
      validateField('categories');
      validateField('cookingTime');
      validateField('preparationTime');
      if (
        values.cookingTime === undefined ||
        values.cookingTime === null ||
        isNaN(values.cookingTime) ||
        values.preparationTime === undefined ||
        values.preparationTime === null ||
        isNaN(values.preparationTime)
      ) {
        return;
      }
    }

    setActiveStep((prev) => (prev < STEPS.length ? prev + 1 : prev));
  };

  const changeStep = (index) => {
    setActiveStep(index);
  };

  return {
    values,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleChangeIngredients,
    handleReorderIngredients,
    handleChangeSteps,
    handleReorderSteps,
    handleChangePortions,
    handleChangeCategories,
    handleChangeIsPublic,
    handleChangeImage,
    handleChangeImageUnsplash,
    addIngredient,
    removeIngredient,
    addStep,
    addSteps,
    removeStep,
    saveInDrafts,
    handleNext,
    changeStep,
    validateForm,
    CATEGORIES,
    activeStep,
    STEPS,
  };
};

export default useCreateRecipe;

