/* eslint-disable indent */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Navbar from '../Templates/Navbar/Navbar';
import Box from '@mui/material/Box';
import { Fade, Grid, useTheme } from '@mui/material';
import { useParams } from 'react-router';
import { Fire } from '../../services';
import { RecipeProps } from '../../types/RecipeProps.type';
import RecipesList from '../RecipesList/RecipesList';
import Infos from '../Templates/Infos/Infos';
import MembersModal from './MembersModal/MembersModal';
import { FamilyType } from '../../types/Family.type';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';

const Family: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [family, setFamily] = React.useState<FamilyType | null>(null);
  const [isLoadingFamily, setIsLoadingFamily] = React.useState(false);
  const [familyRecipes, setFamilyRecipes] = React.useState<RecipeProps[]>([]);
  const [isLoadingRecipes, setIsLoadingRecipes] = React.useState(false);
  const [isMembersOpen, setIsMembersOpen] = React.useState(false);
  const theme = useTheme();
  const mobile = theme.breakpoints.down('xs');

  const onClose = () => {
    setIsMembersOpen(false);
  };

  const onOpen = () => {
    setIsMembersOpen(true);
  };

  const getRecipes = async (ids: string[]) => {
    setIsLoadingRecipes(true);
    try {
      let payloadRecipes = await Promise.all(
        ids.map(async (id) => {
          if (!id) return null;
          try {
            const payload = await Fire.get('recipes', id);
            return payload;
          } catch (error) {
            console.log(error);
            return null;
          }
        })
      );

      payloadRecipes = await payloadRecipes
        .filter(
          (recipe) => recipe !== null && recipe !== undefined && recipe !== ''
        )
        .sort((a, b) => {
          if (a?.date && b?.date) {
            return b.date.seconds - a.date.seconds;
          }
          return 0;
        });

      setFamilyRecipes(payloadRecipes);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingRecipes(false);
  };

  React.useEffect(() => {
    const getFamily = async () => {
      setIsLoadingFamily(true);
      setIsLoadingRecipes(true);
      const ref = Fire.store().collection('families').doc(id);
      const payload = await Fire.doc(ref);
      if (payload?.recipes?.length > 0) {
        getRecipes(payload.recipes);
      } else {
        setIsLoadingRecipes(false);
      }
      setFamily(payload);
      setIsLoadingFamily(false);
    };
    getFamily();
  }, [id]);

  const initials =
    family?.title?.split(' ').length === 1
      ? Array.from(family?.title || '')[0] || ''
      : `${Array.from(family?.title.split(' ')[0] || '')[0] || ''}${
          Array.from(family?.title.split(' ')[1] || '')[0] || ''
        }`;
  const name = `Famille ${family?.title || ''}`;

  return (
    <Box>
      <Navbar />
      <Fade in={true} timeout={500}>
        <Grid
          container
          sx={{
            backgroundColor: 'rgba(239, 35, 60, 0.04)',
          }}
        >
          <Grid
            container
            sx={{
              position: 'relative',
              maxWidth: 1350,
              margin: 'auto',
              padding: '2rem 2rem 4rem 2rem',
              display: 'flex',
              justifyContent: 'space-between',
              [theme.breakpoints.down('sm')]: {
                padding: '2rem',
              },
            }}
          >
            <ButtonFilled
              title="Retour"
              path="/"
              sx={{
                marginBottom: '2rem',
              }}
              arrowLeft
              smallRadius
              smallPadding
            />
            <Infos
              isFamily
              initials={initials}
              name={name}
              isLoading={isLoadingFamily}
              nbRecipes={family?.recipes.length || 0}
              nbMembers={family?.members.length || 0}
              onOpenMembers={onOpen}
              white
            />
          </Grid>
        </Grid>
      </Fade>
      <Fade in={true} timeout={500}>
        <Grid
          container
          sx={{
            position: 'relative',
            maxWidth: 1350,
            margin: 'auto',
            padding: '4rem 2rem',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
              padding: '2rem',
            },
          }}
        >
          <RecipesList
            isLoadingRecipes={isLoadingRecipes}
            recipes={familyRecipes}
            title={mobile ? 'Recettes' : 'Recettes de la famille'}
          />
        </Grid>
      </Fade>
      <MembersModal open={isMembersOpen} onClose={onClose} family={family} />
    </Box>
  );
};

export default Family;

