import React from 'react';
import { Grid, Typography } from '@mui/material';
import Navbar from '../Templates/Navbar/Navbar';
import Footer from '../Templates/Footer/Footer';
import Title from '../Templates/Title/Title';

const Terms = () => (
  <Grid container>
    <Navbar />
    <Grid
      container
      sx={{
        padding: { xs: '3rem 2rem', md: '4.75rem 2rem' },
        maxWidth: '1150px !important',
        margin: '0 auto',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: 'fit-content',
          marginBottom: '3rem',
        }}
      >
        <Title title="Mentions Légales" variant="h1" />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
          pour la confiance en l&rsquo;économie numérique, il est précisé aux
          utilisateurs du site TheKitchenBook l&rsquo;identité des différents
          intervenants dans le cadre de sa réalisation et de son suivi.
        </Typography>
        <Title
          small
          title="Edition du site"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Le présent site, accessible à l’URL https://thekitchenbook.fr (le «
          Site »), est édité par : <br /> <br />
          Louis Pillon
        </Typography>
        <Title
          small
          title="Hébergement"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Le Site est hébergé par la société FIREBASE HOSTING par GOOGLE, situé
          Google LLC, 1600 Amphitheatre Parkway, Mountain View, California 94043
          USA, (contact téléphonique ou email : +33909090909).
        </Typography>
        <Title
          small
          title="Directeur de publication"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Le Directeur de la publication du Site est Louis Pillon.
        </Typography>
        <Title
          small
          title="Nous contacter"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Par email : contact@thekitchenbook.fr
        </Typography>
        <Title
          small
          title="Données personnelles"
          black
          sx={{
            marginBottom: '2rem',
          }}
        />
        <Typography
          variant="body1"
          sx={{
            marginBottom: '2rem',
            fontSize: '1rem',
          }}
        >
          Le traitement de vos données à caractère personnel est régi par notre
          Charte du respect de la vie privée, disponible depuis la section
          &rdquo;Charte de Protection des Données Personnelles&rdquo;,
          conformément au Règlement Général sur la Protection des Données
          2016/679 du 27 avril 2016 («RGPD»).
        </Typography>
      </Grid>
    </Grid>
    <Footer />
  </Grid>
);

export default Terms;

