import React from 'react';
import { OutlinedInput, Typography } from '@mui/material';
import CustomLabel from './CustomLabel';

interface CustomOutlinedInputProps {
  fullWidth?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  sx?: any;
  onKeyDown?: () => void;
  endAdornment?: React.ReactNode;
  type?: string;
  label?: React.ReactElement | string;
  errorMessage?: string;
  error?: boolean;
  placeholder?: string;
  marginBottom?: string;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  defaultValue?: string | number;
}

const CustomOutlinedInput: React.FC<CustomOutlinedInputProps> = ({
  fullWidth = true,
  name,
  onChange,
  value,
  sx,
  onKeyDown,
  endAdornment,
  type,
  label,
  errorMessage,
  error,
  placeholder,
  marginBottom = '1.5rem',
  multiline,
  rows,
  minRows,
  maxRows,
  defaultValue,
}) => {
  return (
    <>
      {label && <CustomLabel label={label} />}
      <OutlinedInput
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        maxRows={maxRows}
        fullWidth={fullWidth}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        value={value}
        error={error}
        sx={{
          marginBottom: marginBottom && !error ? marginBottom : '0',
          input: {
            padding: { xs: '.75rem', sm: '.75rem 1rem' },
          },
          '.MuiInputBase-root': {
            padding: { xs: '.75rem', sm: '.75rem 1rem' },
          },
          // helper text
          '& .MuiFormHelperText-root': {
            color: 'error.main',
            fontSize: '.9rem',
          },
          ...sx,
        }}
        type={type}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && onKeyDown) {
            onKeyDown();
          }
        }}
        endAdornment={endAdornment}
      />
      {errorMessage && (
        <Typography
          variant="body1"
          sx={{
            fontSize: '.9rem',
            fontWeight: 400,
            marginTop: '.5rem',
            color: 'error.main',
            marginBottom: marginBottom && error ? marginBottom : '0',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default CustomOutlinedInput;

