import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/user/userSlice';
import recipeReducer from '../slices/recipe/recipeSlice';

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
  reducer: {
    userReducer: userReducer,
    recipeReducer: recipeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

