import React from 'react';
import { Box, Typography } from '@mui/material';
import { Grade } from '../../../../types/User.type';
import { gradesColor } from '../../../../utils/utils';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

interface GradeRowProps {
  isFirst?: boolean;
  isLast?: boolean;
  step: number;
  title: string;
  description: string;
  grade: Grade;
  isFilled?: boolean;
  percentage: number;
}

const lineSx = {
  width: 2,
  backgroundColor: '#1F202617',
  position: 'absolute',
  left: '5%',
  height: 'calc(50% - 15px)',
  transform: `translateX(-${1}px)`,
};

const GradeRow: React.FC<GradeRowProps> = ({
  isFirst,
  isLast,
  step,
  title,
  description,
  grade,
  isFilled,
  percentage,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        flexDirection: 'row',
        padding: '1rem 0',
      }}
    >
      <Box
        sx={{
          width: '10%',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center',
        }}
      >
        {!isFirst && (
          <Box
            sx={{
              ...lineSx,
              bottom: 'calc(50% + 15px)',
              '&:after': {
                content: "''",
                position: 'absolute',
                display: 'block',
                top: 0,
                left: 0,
                right: 0,
                bottom: isFilled ? 0 : `${100 - percentage}%`,
                backgroundColor: 'primary.main',
              },
            }}
          />
        )}

        <Box
          sx={{
            width: 30,
            height: 30,
            backgroundColor: isFilled ? 'primary.main' : 'white',
            border: `2px solid`,
            borderColor: 'primary.main',
            borderRadius: '50%',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.25rem',
              fontWeight: 600,
              color: isFilled ? 'white' : 'primary.main',
            }}
          >
            {step}
          </Typography>
        </Box>

        {!isLast && (
          <Box
            sx={{
              ...lineSx,
              top: 'calc(50% + 15px)',
              '&:after': {
                content: "''",
                position: 'absolute',
                display: 'block',
                top: 0,
                left: 0,
                right: 0,
                bottom: !isFilled ? '100%' : `${100 - percentage}%`,
                backgroundColor: 'primary.main',
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: '90%',
          paddingLeft: { xs: '2rem', sm: '1rem' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            textAlign: 'left',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.25rem',
              textAlign: 'left',
              fontWeight: 500,
              textTransform: 'uppercase',
              fontFamily: 'Poetsen one',
              marginBottom: '.5rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <WorkspacePremiumIcon
              sx={{
                transition: 'all .2s ease-in-out',
                marginRight: '.5rem',
                color: gradesColor[grade],
              }}
            />
            {title}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GradeRow;

