import { Box, Grid } from '@mui/material';
import React from 'react';
import Title from '../../Templates/Title/Title';
import { Steps } from './HowItWorks.type';
import Row from './Row/Row';
import ButtonFilled from '../../Templates/Buttons/ButtonFilled';
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import AddHomeTwoToneIcon from '@mui/icons-material/AddHomeTwoTone';
import PlayLessonTwoToneIcon from '@mui/icons-material/PlayLessonTwoTone';
import { LoginContext } from '../../../contexts/LoginContext';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';

const steps: Steps = [
  {
    title: 'Étape 1  🎉',
    description:
      "Pour commencer l’aventure, il vous suffit de créer votre <span class='red'>compte personnel</span>.",
    Image: RocketLaunchTwoToneIcon,
  },
  {
    title: 'Étape 2  🏠 ',
    description:
      "Créez ou rejoignez votre <span class='red'>espace familial</span>. Vous pourrez ensuite inviter des membres à le rejoindre.",
    Image: AddHomeTwoToneIcon,
  },
  {
    title: 'Étape 3  📖 ',
    description:
      "<span class='red'>Partagez</span> vos recettes et <span class='red'>interagissez</span> avec celles de votre famille.",
    Image: PlayLessonTwoToneIcon,
  },
  {
    title: 'Étape 4  🧑‍🍳',
    description:
      "Gravissez les <span class='red'>échelons culinaires</span> et décrochez le titre envié de <span class='red'>chef étoilé</span> au sein de votre propre famille.",
    Image: EmojiEventsTwoToneIcon,
  },
];

const HowItWorks: React.FC = () => {
  const { openLogin } = React.useContext(LoginContext);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: '4rem 3.5rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '1350px !important',
        margin: 'auto',
        background: {
          xs: 'rgba(141, 153, 174, 0.04)',
          sm: 'white',
        },
      }}
    >
      <Title title="Comment ça marche ?" />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '4rem',
        }}
      >
        {steps.map((step, index) => (
          <Row
            {...step}
            key={step.title}
            isFirst={index === 0}
            isRevert={index % 2 === 1}
          />
        ))}
        <Box
          sx={{
            marginTop: '4rem',
          }}
        >
          <ButtonFilled
            title="Créez votre compte"
            onClick={() => openLogin('sign-up')}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default HowItWorks;

