/* eslint-disable indent */
import { Box, Button, Modal, Typography } from '@mui/material';
import React from 'react';
import Title from '../Templates/Title/Title';
import ButtonFilled from '../Templates/Buttons/ButtonFilled';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import useCreateRecipe from '../../hooks/useCreateRecipe';
import EastIcon from '@mui/icons-material/East';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { closeModal } from '../../redux/slices/recipe/recipeSlice';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import UnsplashModal from './UnsplashModal/UnsplashModal';

const CreateRecipeModal: React.FC = () => {
  const [errorsMessage, setErrorsMessage] = React.useState('');
  const [unsplashOpen, setUnsplashOpen] = React.useState(false);

  const onCloseUnsplash = async () => {
    setUnsplashOpen(false);
  };

  const onOpenUnsplash = async () => {
    setUnsplashOpen(true);
  };

  const { isModalOpen: open, isUpdating } = useSelector(
    (state: RootState) => state.recipeReducer
  );

  const {
    values,
    handleChange,
    handleChangeCategories,
    handleChangePortions,
    handleSubmit,
    handleChangeIngredients,
    handleReorderIngredients,
    handleChangeSteps,
    handleReorderSteps,
    handleChangeIsPublic,
    handleChangeImage,
    handleChangeImageUnsplash,
    errors,
    addIngredient,
    removeIngredient,
    addStep,
    addSteps,
    removeStep,
    saveInDrafts,
    CATEGORIES,
    STEPS,
    handleNext,
    activeStep,
    changeStep,
    isSubmitting,
    validateForm,
  } = useCreateRecipe();

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal());
  };

  React.useEffect(() => {
    handleErrorsMessage(errors);
  }, [errors]);

  const onSubmit = async () => {
    await validateForm();
    await handleSubmit();
  };
  const handleErrorsMessage = async (res: any) => {
    const newErrorMessages = Object.values(res)
      .map((item: any) => {
        if (item === '' && item === undefined && item === null) return item;
        if (typeof item === 'string') return item;
        if (Array.isArray(item)) {
          return item.map((el) => {
            if (typeof el === 'string') return el;
            if (typeof el?.name === 'string') return el.name;
            return '';
          });
        }
        if (typeof item.quantity === 'string') return item.quantity;
        if (typeof item.measure === 'string') return item.measure;
      })
      .join('\n');
    setErrorsMessage(newErrorMessages);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="Créer une famille"
      aria-describedby="Ce modal sert à créer une famille"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          padding: { xs: '2rem 1.5rem', sm: '3rem' },
          background: 'white',
          borderRadius: 3,
          width: { xs: '93%', sm: '90%' },
          maxWidth: { xs: 700, sm: 900 },
          height: { xs: '75vh', sm: '90vh' },
          maxHeight: 800,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '6%',
            right: '2rem',
            cursor: 'pointer',
          }}
        >
          <CloseRoundedIcon onClick={onClose} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '15%',
          }}
        >
          <Title
            title={isUpdating ? 'Modifier une recette' : 'Ajouter une recette'}
            small
          />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <Stepper
              activeStep={activeStep}
              sx={{
                width: '90%',
                maxWidth: 500,
                '& .MuiStep-root': {
                  padding: '0px !important',
                },

                '& .MuiStepConnector-line': {
                  borderColor: '#EF233C',
                },
              }}
            >
              {STEPS.map((label, index) => (
                <Step
                  key={label}
                  onClick={() => changeStep(index)}
                  sx={{
                    cursor: 'pointer', // hide label
                  }}
                >
                  <StepLabel
                    sx={{
                      cursor: 'pointer !important',
                      '& .MuiStepLabel-label': {
                        display: 'none',
                      },
                      '& .MuiStepLabel-iconContainer': {
                        padding: '0',
                      },
                      '& .MuiSvgIcon-root': {
                        width: '2.5rem',
                        height: '2.5rem',
                        padding: '0',
                        // not completed
                        '& text': {
                          fontFamily: 'Poetsen One',
                          fontSize: '1rem',
                        },
                        '&:not(.Mui-active, .Mui-completed) ': {
                          color: 'white',
                          border: '1px solid #EF233C',
                          borderRadius: '50%',
                          '& text': {
                            fill: '#EF233C',
                          },
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        {activeStep === 0 && (
          <Step1
            key={'add-recipe-step-1'}
            onChange={handleChange}
            title={values.title}
            introduction={values.introduction}
            titleError={errors.title}
            introductionError={errors.introduction}
            image={values.image}
            onChangeImage={handleChangeImage}
            imageError={errors.image}
            openUnsplash={onOpenUnsplash}
          />
        )}
        {activeStep === 1 && (
          <Step2
            key={'add-recipe-step-2'}
            onChange={handleChange}
            onChangeIngredients={handleChangeIngredients}
            handleReorderIngredients={handleReorderIngredients}
            title={values.title}
            ingredients={values.ingredients}
            addIngredient={addIngredient}
            removeIngredient={removeIngredient}
            ingredientsError={errors.ingredients}
            onChangePortions={handleChangePortions}
            portions={values.portions}
            portionsError={errors.portions}
          />
        )}
        {activeStep === 2 && (
          <Step3
            key={'add-recipe-step-3'}
            onChangeSteps={handleChangeSteps}
            handleReorderSteps={handleReorderSteps}
            steps={values.recipe}
            addStep={addStep}
            addSteps={addSteps}
            removeStep={removeStep}
            stepsError={errors.recipe}
          />
        )}
        {activeStep === 3 && (
          <Step4
            key={'add-recipe-step-4'}
            onChange={handleChange}
            onChangeCategories={handleChangeCategories}
            categories={values.categories}
            preparationTime={values.preparationTime}
            cookingTime={values.cookingTime}
            CATEGORIES={CATEGORIES}
            categoriesError={errors.categories}
            preparationTimeError={errors.preparationTime}
            cookingTimeError={errors.cookingTime}
          />
        )}
        {activeStep === 4 && (
          <Step5
            key={'add-recipe-step-5'}
            onChange={handleChange}
            onChangeIsPublic={handleChangeIsPublic}
            families={values.families}
            isPublic={values.isPublic}
          />
        )}

        {activeStep === 4 && errorsMessage && errorsMessage !== '' && (
          <Typography
            variant="body1"
            sx={{
              color: 'error.main',
              textAlign: 'left',
              marginTop: '1rem',
              marginBottom: '1rem',
              fontWeight: 500,
            }}
          >
            {errorsMessage.split('\n').map((item, key) => (
              <Typography
                component={'span'}
                key={key}
                sx={{
                  display: 'block',
                  marginBottom: '0.5rem',
                }}
              >
                {item}
              </Typography>
            ))}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 'auto',
            flexDirection: 'column',
          }}
        >
          <ButtonFilled
            onClick={() => {
              activeStep < STEPS.length - 1 ? handleNext() : onSubmit();
            }}
            disabled={isSubmitting}
            title={
              isSubmitting
                ? 'Chargement...'
                : activeStep === 0
                ? "C'est parti !"
                : activeStep === STEPS.length - 1
                ? 'Publier ma recette'
                : 'Étape suivante'
            }
            endIcon={
              activeStep === STEPS.length - 1 ? (
                <DoneIcon sx={{ fontSize: '2rem' }} />
              ) : (
                <EastIcon sx={{ fontSize: '2rem' }} />
              )
            }
          />
          <Button
            onClick={() => {
              saveInDrafts();
              onClose();
            }}
            sx={{
              marginTop: '1rem',
              color: 'text.primary',

              '&:hover': {
                backgroundColor: 'transparent',
                color: 'text.secondary',
              },
            }}
          >
            Enregistrer dans les brouillons
          </Button>
        </Box>
        <UnsplashModal
          onClose={onCloseUnsplash}
          open={unsplashOpen}
          handleChangeImageUnsplash={handleChangeImageUnsplash}
          defaultValue={values.title}
        />
      </Box>
    </Modal>
  );
};

export default CreateRecipeModal;

